export function formatDollar(
  value: number | undefined,
  decimalPlaces: number = 2
): string {
  if (!value) {
    return '-';
  }

  return `$${(+value).toFixed(decimalPlaces)}`;
}
