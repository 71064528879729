import React, { ReactElement } from 'react';
import { GameMakerAsset } from 'src/lib/types/gameMakerAsset';
import Iconify, { IconifyProps } from 'src/template/components/Iconify';

const MainInfo = React.lazy(
  () => import('src/components/modules/GameMakerAsset/view/MainInfo')
);

export enum AssetTabs {
  MAIN = 'main_info',
}

interface IAssetTabs {
  value: AssetTabs;
  icon: ReactElement<IconifyProps>;
  component: (asset: GameMakerAsset) => JSX.Element;
}

const ASSET_TABS: IAssetTabs[] = [
  {
    value: AssetTabs.MAIN,
    icon: (
      <Iconify icon="material-symbols:info-outline" width={20} height={20} />
    ),
    component: (asset) => <MainInfo assetDetails={asset} />,
  },
];

export default ASSET_TABS;
