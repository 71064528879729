import {
  ContentCopy,
  Delete,
  Edit,
  OpenInNew,
  Shield,
} from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import CopyToClipboard from 'react-copy-to-clipboard';
import { reviewStatusColumns } from 'src/components/modules/Review/reviewStatusColumn';
import MagicTableGameCell from 'src/components/shared/MagicTable/GameCell';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { AdminListGamesRow } from 'src/lib/types/gameRow';
import { GameVersion } from 'src/lib/types/gameVersion';
import { OBJECT_MODERATION_OBJECT_TYPE } from 'src/lib/types/objectModeration';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Label from 'src/template/components/Label';
import useGamesTable from './useView';

type GamesTableQueryParams = {
  page: number;
  size: number;
  orderBy: string;
  sortOrder: string;
};

type GamesTableProps = {
  data?: AdminListGamesRow[];
  totalCount?: number;
  isLoading: boolean;
  params: GamesTableQueryParams;
};

export default function GamesTable({
  data,
  isLoading,
  params: { page, size, orderBy, sortOrder },
  totalCount,
}: GamesTableProps) {
  const { addParam, removeParam } = useURLSearchParams();
  const {
    handleOpenDeleteModal,
    handleOpenUpdateModal,
    handleOpenQrCodeModal,
    handleOpenReviewModal,
  } = useGamesTable();

  return (
    <MagicTable
      rowHeight={64}
      loading={isLoading}
      pageSize={size}
      page={page}
      paginationMode="server"
      onPageChange={(newPage) => {
        addParam('page', newPage);
      }}
      onPageSizeChange={(newSize) => {
        addParam('size', newSize);
      }}
      onSortModelChange={(model) => {
        const currentModel = model[0];
        if (currentModel) {
          addParam('orderBy', currentModel.field);
          if (currentModel.sort) addParam('order', currentModel.sort);
        } else {
          removeParam('order');
          removeParam('orderBy');
        }
      }}
      sortModel={[
        {
          field: orderBy,
          sort: sortOrder as GridSortDirection,
        },
      ]}
      rows={data ?? []}
      rowCount={totalCount ?? undefined}
      columns={[
        //* This collumn is deactivated. To handle game activation/deactivation, update the GameVersion.
        // {
        //   field: 'status',
        //   headerName: 'Status',
        //   align: 'center',
        //   flex: 0,
        //   renderCell({ row }) {
        //     const active = row.status === 'active';
        //     return (
        //       <Stack
        //         alignItems="center"
        //         justifyContent="center"
        //         spacing={0}
        //       >
        //         <Switch
        //           checked={active}
        //           color="success"
        //           onChange={() =>
        //             handleGameStatusChange(
        //               row.uuid,
        //               row.status as 'active' | 'inactive'
        //             )
        //           }
        //           size="small"
        //           disabled={
        //             (isChangingStatus &&
        //               rowUuidChangingStatus === row.uuid) ||
        //             isLoading
        //           }
        //         />
        //         <Box textAlign="center" fontSize={12}>
        //           (
        //           {rowUuidChangingStatus === row.uuid
        //             ? 'Changing...'
        //             : row.status}
        //           )
        //         </Box>
        //       </Stack>
        //     );
        //   },
        // },
        {
          field: 'name',
          headerName: 'Name',
          renderCell({ row }) {
            return <MagicTableGameCell game={row} />;
          },
        },
        {
          field: 'owner',
          headerName: 'Owner',
          sortable: false,
          renderCell({ row }) {
            return <MagicTableUserCell user={row.owner} />;
          },
        },
        /*
            {
              field: 'modesStrings',
              headerName: 'Game Modes',
              flex: 1,
              sortable: false,
              renderCell({ row }) {
                return row.modesStrings.join(', ') ?? '-';
              },
            },
            {
              field: 'categoriesStrings',
              headerName: 'Categories',
              flex: 1,
              sortable: false,
              renderCell({ row }) {
                return row.categoriesStrings.join(', ') ?? '-';
              },
            },
            {
              field: 'tagsStrings',
              headerName: 'Tags',
              flex: 1,
              sortable: false,
              renderCell({ row }) {
                return row.tagsStrings.join(', ') ?? '-';
              },
            },
            {
              field: 'description',
              headerName: 'Description',
              flex: 1,
            },
            */
        {
          field: 'isAi',
          headerName: 'By Game Maker',
          flex: 1 / 2,
          renderCell({ row }) {
            return (
              <Label color={row.isAi ? 'success' : 'error'}>
                {row.isAi ? 'Yes' : 'No'}
              </Label>
            );
          },
        },
        {
          field: 'isGameMakerTemplate',
          headerName: 'Is Template',
          flex: 1 / 2,
          sortable: false,
          renderCell({ row }) {
            return (
              <Label color={row.isGameMakerTemplate ? 'success' : 'error'}>
                {row.isGameMakerTemplate ? 'Yes' : 'No'}
              </Label>
            );
          },
        },
        ...reviewStatusColumns.map((column) => ({
          ...column,
          renderCell: ({ row }: { row: any }) =>
            row.versions?.[0]
              ? column.renderCell({
                  row: row.versions?.[0] as GameVersion,
                })
              : null,
        })),
        /*
            {
              field: 'gamePublisherUuid',
              headerName: 'Publisher',
              flex: 1 / 2,
              renderCell({ row }) {
                return row.publisher?.name ?? '-';
              },
              sortable: false,
            },
        */
        /*
        {
          field: 'developerName',
          headerName: 'Developer',
          flex: 1 / 2,
          sortable: true,
        }, 
        */
        {
          field: 'createdAt',
          headerName: 'Created At',
          sortingOrder: ['desc', 'asc'],
          flex: 1 / 2,
          valueFormatter({ value }) {
            return value ? formatUTCDate(value) : '-';
          },
        },
        /* {
              field: 'Contests',
              headerName: '',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <Link to={Routes.game_center.game.contests(row.uuid)}>
                    <Button color="info" variant="contained">
                      Contests
                    </Button>
                  </Link>
                );
              },
            },
            {
              field: 'Rankings',
              headerName: '',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <Link to={Routes.game_center.game.rankings(row.uuid)}>
                    <Button color="warning" variant="contained">
                      Rankings
                    </Button>
                  </Link>
                );
              },
            },
            {
              field: 'Statistic Groups',
              headerName: '',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <Link to={Routes.game_center.game.statistic_groups(row.uuid)}>
                    <Button color="primary" variant="contained">
                      Statistics
                    </Button>
                  </Link>
                );
              },
            }, */
        {
          field: 'actions',
          headerName: 'Actions',
          align: 'center',
          sortable: false,
          flex: 0,
          renderCell({ row }) {
            return (
              <MoreMenu>
                <CopyToClipboard text={row.uuid}>
                  <MenuItem aria-label="copy feed uuid">
                    <ContentCopy />
                    Copy UUID
                  </MenuItem>
                </CopyToClipboard>
                <MenuItem
                  onClick={() => {
                    handleOpenQrCodeModal({
                      title: row.name,
                      url: row?.versions?.[0]?.url,
                    });
                  }}
                >
                  <OpenInNew />
                  QR Code
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleOpenReviewModal({
                      objectType: OBJECT_MODERATION_OBJECT_TYPE.GAME_VERSION,
                      objectUuid: row.versions?.[0]?.uuid,
                      adminStatus: row.versions?.[0]?.status,
                      userStatus: row.versions?.[0]?.userStatus,
                    });
                  }}
                >
                  <Shield />
                  Review
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleOpenUpdateModal(row);
                  }}
                >
                  <Edit />
                  Update
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleOpenDeleteModal(row.uuid);
                  }}
                >
                  <Delete />
                  Delete
                </MenuItem>
              </MoreMenu>
            );
          },
        },
      ]}
    />
  );
}
