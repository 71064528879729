import useModalStore from 'src/lib/stores/useModalStore';
import {
  OBJECT_MODERATION_HUMAN_REVIEW,
  OBJECT_MODERATION_OBJECT_TYPE,
  ObjectAdminStatus,
  ObjectUserStatus,
} from 'src/lib/types/objectModeration';
import ReviewForm from '.';

interface OpenReviewModalProps {
  objectUuid: string;
  objectType: OBJECT_MODERATION_OBJECT_TYPE;
  adminStatus?: ObjectAdminStatus;
  userStatus?: ObjectUserStatus;
}

export const useReviewModal = () => {
  const { openModal } = useModalStore();

  const handleOpenReviewModal = ({
    objectUuid,
    objectType,
    adminStatus,
    userStatus,
  }: OpenReviewModalProps) => {
    openModal(
      <ReviewForm
        objectUuid={objectUuid}
        objectType={objectType}
        adminStatus={adminStatus}
        userStatus={userStatus}
        defaultValues={{
          humanReview: OBJECT_MODERATION_HUMAN_REVIEW.LIMITED_APPROVAL,
        }}
      />
    );
  };

  return { handleOpenReviewModal };
};
