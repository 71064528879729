import { OldTransaction } from 'src/lib/types/OldTransaction';
import {
  TransactionHistoryPossibleTypes,
  TransactionHistoryTableType,
} from 'src/lib/types/TransactionHistory';
import formatStickyCoinValue from '../formatStickyCoinValue';
import { getTruncatedText } from '../formatters';

const getNFTNameFromTransaction = (transaction: OldTransaction): string =>
  transaction?.nft?.name || 'Empty NFT name';

export const buyStickerType = (
  transaction: OldTransaction,
  userWallet: string
): string => {
  const { nfts, objectProvider, walletFromUuid } = transaction;

  const isPurchase = walletFromUuid === userWallet;

  if (objectProvider === 'transfer') {
    return walletFromUuid === userWallet
      ? 'Received an NFT Transfer'
      : 'Transferred an NFT';
  }
  const isOnMarketplace = objectProvider === 'marketplace';
  const isMoreThanOne = nfts > 1;
  return `NFT${isMoreThanOne ? 's' : ''} ${isPurchase ? 'Purchase' : 'Sale'} ${
    isOnMarketplace ? 'on Marketplace' : ''
  }`;
};

export const buyStickerDescription = (transaction: OldTransaction): string => {
  const { objectProvider, amount } = transaction;
  const nftName = getNFTNameFromTransaction(transaction);
  if (objectProvider === 'stickers' && amount === 0) {
    return `(Pack) ${nftName}`;
  }
  return nftName;
};

export const importStickerType = (transaction: OldTransaction): string => {
  const { walletFromUuid } = transaction;
  if (walletFromUuid) return 'Receive an NFT transfer';
  return 'Transferred an NFT';
};

export const importStickerDescription = (transaction: OldTransaction): string =>
  getNFTNameFromTransaction(transaction);

export const sellStickerType = (): string => 'NFT Sale';

export const sellStickerDescription = (transaction: OldTransaction): string =>
  getNFTNameFromTransaction(transaction);

export const mintStickerType = (): string => 'NFT Creation';

export const mintStickerDescription = (): string => 'NFT Creation';

export const paymentRequestType = (): string => 'Payment Request';

export const paymentRequestDescription = (
  transaction: OldTransaction
): string => {
  const { object: paymentRequest } = transaction;

  if (!paymentRequest) return 'Untracked';

  const { amount, value, status } = paymentRequest;
  const amountValue = formatStickyCoinValue(amount);

  switch (status) {
    case 'executed':
      return `(Executed) + $ ${value} ${amountValue} Coins`;

    case 'canceled':
      return `(Canceled) + ${amountValue} Coins`;
    case 'requested':
      return `(Requested) - ${amountValue} Coins`;
    default:
      return `(Untracked) Payment Request`;
  }
};
export const rechargeType = (transaction: OldTransaction): string => {
  const { mint, nfts, amount, objectProvider } = transaction;
  if (mint > 0) return `Mint Passes Purchase`;
  if (nfts > 0) return `${nfts} NFT Pack Purchase`;
  if (amount > 0 && objectProvider === 'apple') {
    return `Coins Purchase (Apple)`;
  }
  if (objectProvider === 'stickers') {
    return `Daily Bonus`;
  }
  if (objectProvider === 'gamification') {
    return `Free Coins`;
  }
  return `Coins Purchase`;
};

export const rechargeDescription = (transaction: OldTransaction): string => {
  const { mint, nfts, amount, objectProvider } = transaction;
  if (mint > 0) return `Mint Pass Purchase`;
  if (nfts > 0) return `NFT Pack Purchase`;
  if (amount > 0 && objectProvider === 'apple') {
    return `Coins Purchase`;
  }
  if (objectProvider === 'gamification') {
    return `Free Coins`;
  }
  if (objectProvider === 'stickers') {
    return `Daily Bonus`;
  }
  return `(Untracked) Coins Purchase`;
};
export const gasChargeType = (): string => 'Gas Charge';
export const gasChargeDescription = (): string => 'Gas Charge';

export const serviceFeeType = (): string => 'Service Fee';
export const serviceFeeDescription = (): string => 'Service Fee';

export const refundCoinsType = (): string => 'Refund Coins';
export const refundCoinsDescription = (): string => 'Refund Coins';

export const refundMintPassesType = (): string => 'Refund Mint';
export const refundMintPassesDescription = (): string => 'Refund Mint';

export const refundNftsType = (): string => 'Refund NFTs';
export const refundNftsDescription = (): string => 'Refund NFTs';

export const assetConvertionType = (): string => 'Asset Conversion';
export const assetConvertionDescription = (): string => 'Asset Conversion';

export const coinsBonitificationType = (): string => 'Coins Bonification';
export const coinsBonitificationDescription = (): string =>
  'Coins Bonification';

export const nftBonificationType = (): string => 'NFT Bonification';
export const nftBonificationDescription = (): string => 'NFT Bonification';

export const createCollectionType = (): string => 'Create Collection';
export const createCollectionDescription = (): string => 'Create Collection';

export const createArtType = (): string => 'Create Art';
export const createArtDescription = (): string => 'Create Art';

export const stickyCoinsAmount = (transaction: OldTransaction): string => {
  const { type, amount } = transaction;
  if (amount === 0) return '-';
  const formattedValue = formatStickyCoinValue(amount);
  if (type === 'buy_sticker') return `- ${formattedValue} Coins`;
  if (type === 'sell_sticker') return `+ ${formattedValue} Coins`;
  return `${formattedValue}`;
};

// ***********************************************

export const getTransactionType = (
  transaction: OldTransaction,
  walletFromUuid: string
): string => {
  const { type } = transaction;
  switch (type) {
    case 'buy_sticker':
      return buyStickerType(transaction, walletFromUuid);
    case 'import_sticker':
      return importStickerType(transaction);
    case 'mint_sticker':
      return 'NFT Creation';
    case 'payment_request':
      return paymentRequestType();
    case 'recharge':
      return rechargeType(transaction);
    default:
      return 'Untracked';
  }
};

export const getTransactionCoins = (
  transaction: OldTransaction,
  walletFromUuid: string
): string => {
  const { type } = transaction;

  const isPurchase = transaction.walletFromUuid === walletFromUuid;

  switch (type) {
    case 'buy_sticker':
      return transaction.amount !== 0
        ? `${isPurchase ? '- ' : '+ '}${transaction.amount}`
        : `${transaction.amount}`;
    case 'recharge':
      return transaction.amount !== 0 ? `+ ${transaction.amount}` : '-';
    case 'import_sticker':
    case 'mint_sticker':
    case 'payment_request':
    default:
      return `${transaction.amount || '-'}`;
  }
};

export const getTransactionMint = (transaction: OldTransaction): string =>
  `${transaction.mint || '-'}`;

export const getTransactionNftsPacks = (transaction: OldTransaction): string =>
  `${transaction.nfts || '-'}`;

export const getTransactionDescription = (
  transaction: OldTransaction
): string => {
  const { type, nfts } = transaction;
  if (type === 'buy_sticker')
    return `${transaction.nft?.name || 'NFT name not found'}`;
  if (type === 'recharge' && nfts > 0) return `${transaction.categoryUuid}`;
  return '-';
};

export const getTransactionUser = (
  transaction: OldTransaction,
  walletFromUuid: string
):
  | {
      uuid: string;
      slug: string;
    }
  | undefined => {
  const { walletFrom, walletTo } = transaction;

  if (!walletFrom && !walletTo) return undefined;

  if (walletFrom?.uuid === walletFromUuid && walletTo) {
    return {
      uuid: walletTo.user.uuid,
      slug: walletTo.user?.slug ?? '',
    };
  }

  return {
    uuid: walletFrom.user.uuid,
    slug: walletFrom.user?.slug ?? '',
  };
};

const prices = {
  coins: {
    '40000': 9.99,
    '70000': 7.99,
    '400000': 49.99,
    '1200000': 99.99,
  },
  mint: {
    '30': 9.99,
    '175': 49.99,
    '375': 99.99,
  },
  nftPack: {
    '3': 4.99,
    '7': 9.99,
    '42': 49.99,
    '98': 99.99,
  },
};

export function getAproxPrice(transaction: OldTransaction) {
  const { mint, nfts, amount, type } = transaction;
  if (type === 'recharge') {
    if (mint > 0)
      return `$${prices.mint[`${mint}` as '30' | '175' | '375'] ?? 'N/A'}`;
    if (nfts > 0)
      return `$${prices.nftPack[`${nfts}` as '7' | '42' | '98'] ?? 'N/A'}`;
    if (amount > 0)
      return `$${
        prices.coins[`${amount}` as '40000' | '400000' | '1200000'] ?? 'N/A'
      }`;
  }
  return '-';
}

export const transactionHistoryTableParser = (
  transactionHistory: TransactionHistoryPossibleTypes[]
): TransactionHistoryTableType[] =>
  transactionHistory.map(
    (t): TransactionHistoryTableType => ({
      poolId: t.poolUuid,
      createdAt: t.createdAt,
      updatedAt: t.createdAt,
      type: t.type,
      coins: t.coins,
      gasPaidInCoins: t.gasPaidInCoins,
      paymentRequest: t?.paymentRequest,
      from:
        t?.globalWalletFrom && t.globalWalletFromUuid
          ? {
              userId: t?.globalWalletFrom?.userUuid,
              userName:
                t?.globalWalletFrom?.user?.name ||
                t?.globalWalletFrom?.user?.slug ||
                t?.globalWalletFrom?.user?.email ||
                getTruncatedText(t?.globalWalletFrom.uuid, 4, 3),
              walletAddress: t?.globalWalletFrom?.cryptoWallet?.walletAddress,
            }
          : t?.sellerGlobalWallet && t?.sellerGlobalWallet?.user
          ? {
              userId: t?.sellerGlobalWallet?.userUuid,
              userName:
                t?.sellerGlobalWallet?.user?.name ||
                t?.sellerGlobalWallet?.user?.slug ||
                t?.sellerGlobalWallet?.user?.email ||
                getTruncatedText(t?.sellerGlobalWallet.uuid, 4, 3),
              walletAddress: t?.sellerGlobalWallet?.cryptoWallet?.walletAddress,
            }
          : undefined,
      to: t?.globalWalletTo
        ? {
            userId: t?.globalWalletTo?.userUuid,
            userName:
              t?.globalWalletTo?.user?.name ||
              t?.globalWalletTo?.user?.slug ||
              t?.globalWalletTo?.user?.email ||
              getTruncatedText(t?.globalWalletTo.uuid, 4, 3),
            walletAddress: t?.globalWalletTo?.cryptoWallet?.walletAddress,
          }
        : t?.buyerGlobalWallet && t?.buyerGlobalWallet?.user
        ? {
            userId: t?.buyerGlobalWallet?.userUuid,
            userName:
              t?.buyerGlobalWallet?.user?.name ||
              t?.buyerGlobalWallet?.user?.slug ||
              t?.buyerGlobalWallet?.user?.email ||
              getTruncatedText(t?.buyerGlobalWallet.uuid, 4, 3),
            walletAddress: t?.buyerGlobalWallet?.cryptoWallet?.walletAddress,
          }
        : undefined,
      asset: parseAsset(t),
      assetType: getAssetType(t),
      status: t?.status ?? undefined,
      editions: t?.editions ?? t.nftEditions,
      shards: t?.shards,
      prompts: t?.prompts,
      credits: t?.credits,
      service: t?.service,
      hasNote: t.hasNote,
      originalType: t.originalType,
      eventType: t.eventType,
    })
  );

function parseAsset(
  t: TransactionHistoryPossibleTypes
): { id: string; name: string; url: string } | undefined {
  if (t?.nft) {
    return {
      id: t?.nft?.uuid,
      name: t?.nft?.name,
      url: t?.nft?.fileUrl,
    };
  }
  if (t?.nfts && t.nfts.length === 1) {
    const nft = t.nfts[0];
    return {
      id: nft.uuid,
      name: nft.name,
      url: nft.fileUrl,
    };
  }
  if (t?.nftUuids) {
    return {
      id: '',
      name: `${t?.nftUuids?.length} NFTs`,
      url: '',
    };
  }
  if (t?.collection) {
    return {
      id: t.collection.uuid,
      name: t.collection.name,
      url: t.collection.coverFileUrl,
    };
  }
  if (t?.rewardedShards?.length > 0) {
    return {
      id: '',
      name: `${t.rewardedShards.length} NFTs`,
      url: '',
    };
  }
  if (t?.productId) {
    return {
      id: '',
      name: t.productId,
      url: '',
    };
  }
  return undefined;
}

function getAssetType(
  t: TransactionHistoryPossibleTypes
): 'nft' | 'collection' | undefined {
  if (
    t.nft ||
    t.nftUuid ||
    (t.nfts && t.nfts.length > 0) ||
    t.rewardedShards ||
    t.shards
  )
    return 'nft';
  if (t.collection) return 'collection';
  return undefined;
}
