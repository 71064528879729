import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { Channel } from 'src/lib/types/channel';
import { OBJECT_MODERATION_OBJECT_TYPE } from 'src/lib/types/objectModeration';
import { getMessageFromError } from 'src/lib/utils/error';
import { ModerationForm } from '../components/ReviewForm/types';

export async function sendHumanReview(
  objectUuid: string,
  objectType: OBJECT_MODERATION_OBJECT_TYPE,
  data: ModerationForm
): Promise<ServiceResponse<Channel>> {
  try {
    const api = await getAPIClient();
    const res = await api.post(
      `admin/object-moderation/human-review/${objectType}/${objectUuid}`,
      data
    );
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
