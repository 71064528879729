import AssetDetails from 'src/components/modules/GameMakerAsset/view/Details';
import Page from 'src/template/components/Page';

export default function GameMakerAssetPage() {
  return (
    <Page title="Asset ">
      <AssetDetails />
    </Page>
  );
}
