import {
  OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED,
  OBJECT_MODERATION_HUMAN_REVIEW,
  OBJECT_MODERATION_OBJECT_TYPE,
  OBJECT_MODERATION_REVIEW_STATUS,
  ObjectActionablesStatus,
  ObjectAdminStatus,
  ObjectUserStatus,
  ObjectVisibility,
  ObjectVisibleStatus,
  StatusesAndActionablesOnObjectParams,
} from 'src/lib/types/objectModeration';

export const objectStatusAndActionables: Record<
  string,
  StatusesAndActionablesOnObjectParams
> = {
  [`${OBJECT_MODERATION_REVIEW_STATUS.UNREVIEWED}_${ObjectUserStatus.PRIVATE}`]:
    {
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
      visibility: ObjectVisibility.PRIVATE,
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.UNREVIEWED,
      visibleStatus: ObjectVisibleStatus.IN_REVIEW,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.UNREVIEWED}_${ObjectUserStatus.PUBLIC}`]:
    {
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
      visibility: ObjectVisibility.PRIVATE,
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.UNREVIEWED,
      visibleStatus: ObjectVisibleStatus.IN_REVIEW,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_REJECTED}_${ObjectUserStatus.PRIVATE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_REJECTED,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.NEW,
      visibleStatus: ObjectVisibleStatus.NEEDS_REVISION,
      visibility: ObjectVisibility.HIDDEN,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.OFF,
      transfer: ObjectActionablesStatus.OFF,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_REJECTED}_${ObjectUserStatus.PUBLIC}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_REJECTED,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.NEW,
      visibleStatus: ObjectVisibleStatus.NEEDS_REVISION,
      visibility: ObjectVisibility.HIDDEN,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.OFF,
      transfer: ObjectActionablesStatus.OFF,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_INCONCLUSIVE}_${ObjectUserStatus.PRIVATE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_INCONCLUSIVE,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.NEW,
      visibleStatus: ObjectVisibleStatus.IN_REVIEW,
      visibility: ObjectVisibility.PRIVATE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_INCONCLUSIVE}_${ObjectUserStatus.PUBLIC}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_INCONCLUSIVE,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.NEW,
      visibleStatus: ObjectVisibleStatus.IN_REVIEW,
      visibility: ObjectVisibility.PRIVATE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED}_${ObjectUserStatus.PRIVATE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED,
      visibility: ObjectVisibility.PRIVATE,
      visibleStatus: ObjectVisibleStatus.PRIVATE,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.ON,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED}_${ObjectUserStatus.PUBLIC}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED,
      visibility: ObjectVisibility.PRIVATE,
      visibleStatus: ObjectVisibleStatus.PRIVATE,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.ON,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.REJECTION}_${ObjectUserStatus.PRIVATE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.REJECTION,
      visibleStatus: ObjectVisibleStatus.NEEDS_REVISION,
      visibility: ObjectVisibility.HIDDEN,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.OFF,
      transfer: ObjectActionablesStatus.OFF,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.REJECTION}_${ObjectUserStatus.PUBLIC}`]: {
    reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.REJECTION,
    visibleStatus: ObjectVisibleStatus.NEEDS_REVISION,
    visibility: ObjectVisibility.HIDDEN,
    editability: ObjectActionablesStatus.ON,
    monetization: ObjectActionablesStatus.OFF,
    social: ObjectActionablesStatus.OFF,
    transfer: ObjectActionablesStatus.OFF,
    humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
  },
  [`${OBJECT_MODERATION_REVIEW_STATUS.LIMITED_APPROVAL}_${ObjectUserStatus.PRIVATE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.LIMITED_APPROVAL,
      visibleStatus: ObjectVisibleStatus.PRIVATE,
      visibility: ObjectVisibility.PRIVATE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.ON,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.LIMITED_APPROVAL}_${ObjectUserStatus.PUBLIC}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.LIMITED_APPROVAL,
      visibleStatus: ObjectVisibleStatus.PUBLIC,
      visibility: ObjectVisibility.LIMITED,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.ON,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.FULL_APPROVAL}_${ObjectUserStatus.PRIVATE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.FULL_APPROVAL,
      visibleStatus: ObjectVisibleStatus.PRIVATE,
      visibility: ObjectVisibility.PRIVATE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.ON,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.FULL_APPROVAL}_${ObjectUserStatus.PUBLIC}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.FULL_APPROVAL,
      visibleStatus: ObjectVisibleStatus.PUBLIC,
      visibility: ObjectVisibility.EVERYWHERE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.ON,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.PRIVATE}_${ObjectUserStatus.PRIVATE}`]: {
    reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.PRIVATE,
    visibleStatus: ObjectVisibleStatus.PRIVATE,
    visibility: ObjectVisibility.PRIVATE,
    editability: ObjectActionablesStatus.ON,
    monetization: ObjectActionablesStatus.OFF,
    social: ObjectActionablesStatus.ON,
    transfer: ObjectActionablesStatus.ON,
    humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
  },
  [`${OBJECT_MODERATION_REVIEW_STATUS.PRIVATE}_${ObjectUserStatus.PUBLIC}`]: {
    reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.PRIVATE,
    visibleStatus: ObjectVisibleStatus.PRIVATE,
    visibility: ObjectVisibility.PRIVATE,
    editability: ObjectActionablesStatus.ON,
    monetization: ObjectActionablesStatus.OFF,
    social: ObjectActionablesStatus.ON,
    transfer: ObjectActionablesStatus.ON,
    humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
  },

  // USER EXCEPTIONS
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED}_${ObjectUserStatus.PUBLIC}_${OBJECT_MODERATION_OBJECT_TYPE.USER}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED,
      visibility: ObjectVisibility.LIMITED,
      visibleStatus: ObjectVisibleStatus.PUBLIC,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.ON,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
    },

  // GAME EXCEPTIONS
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_REJECTED}_${ObjectUserStatus.PRIVATE}_${OBJECT_MODERATION_OBJECT_TYPE.GAME_VERSION}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_REJECTED,
      visibility: ObjectVisibility.PRIVATE,
      visibleStatus: ObjectVisibleStatus.IN_REVIEW,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.NEW,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.OFF,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_REJECTED}_${ObjectUserStatus.PUBLIC}_${OBJECT_MODERATION_OBJECT_TYPE.GAME_VERSION}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_REJECTED,
      visibility: ObjectVisibility.PRIVATE,
      visibleStatus: ObjectVisibleStatus.IN_REVIEW,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.NEW,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.OFF,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED}_${ObjectUserStatus.PRIVATE}_${OBJECT_MODERATION_OBJECT_TYPE.GAME_VERSION}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED,
      visibility: ObjectVisibility.PRIVATE,
      visibleStatus: ObjectVisibleStatus.PRIVATE,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.NEW,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED}_${ObjectUserStatus.PUBLIC}_${OBJECT_MODERATION_OBJECT_TYPE.GAME_VERSION}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED,
      visibility: ObjectVisibility.LIMITED,
      visibleStatus: ObjectVisibleStatus.PUBLIC,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.NEW,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.ON,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
    },

  // VIDEO EXCEPTIONS
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_REJECTED}_${ObjectUserStatus.PRIVATE}_${OBJECT_MODERATION_OBJECT_TYPE.VIDEO}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_REJECTED,
      visibility: ObjectVisibility.PRIVATE,
      visibleStatus: ObjectVisibleStatus.IN_REVIEW,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.NEW,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.OFF,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_REJECTED}_${ObjectUserStatus.PUBLIC}_${OBJECT_MODERATION_OBJECT_TYPE.VIDEO}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_REJECTED,
      visibility: ObjectVisibility.PRIVATE,
      visibleStatus: ObjectVisibleStatus.IN_REVIEW,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.NEW,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.OFF,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED}_${ObjectUserStatus.PRIVATE}_${OBJECT_MODERATION_OBJECT_TYPE.VIDEO}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED,
      visibility: ObjectVisibility.PRIVATE,
      visibleStatus: ObjectVisibleStatus.PRIVATE,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.NEW,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED}_${ObjectUserStatus.PUBLIC}_${OBJECT_MODERATION_OBJECT_TYPE.VIDEO}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED,
      visibility: ObjectVisibility.LIMITED,
      visibleStatus: ObjectVisibleStatus.PUBLIC,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.NEW,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.ON,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
    },
};

export function getStatusesAndActionablesByModerationStatus(
  reviewStatus: OBJECT_MODERATION_REVIEW_STATUS,
  userStatus: ObjectUserStatus,
  adminStatus: ObjectAdminStatus,
  objectType?: OBJECT_MODERATION_OBJECT_TYPE
): StatusesAndActionablesOnObjectParams {
  const statusCombinationKeyWithObjectType = `${reviewStatus}_${userStatus}_${objectType}`;
  const statusCombinationKey = `${reviewStatus}_${userStatus}`;
  const statusAndActionables =
    objectStatusAndActionables[statusCombinationKeyWithObjectType] ?? // Specific object type
    objectStatusAndActionables[statusCombinationKey]; // Generic

  const finalVisibility =
    adminStatus === ObjectAdminStatus.ACTIVE
      ? statusAndActionables?.visibility
      : ObjectVisibility.NOT_VISIBLE;

  return {
    ...statusAndActionables,
    visibility: finalVisibility,
  };
}

export const reviewStatusesByHumanReview: Record<
  OBJECT_MODERATION_HUMAN_REVIEW,
  OBJECT_MODERATION_REVIEW_STATUS
> = {
  [OBJECT_MODERATION_HUMAN_REVIEW.FULL_APPROVAL]:
    OBJECT_MODERATION_REVIEW_STATUS.FULL_APPROVAL,
  [OBJECT_MODERATION_HUMAN_REVIEW.LIMITED_APPROVAL]:
    OBJECT_MODERATION_REVIEW_STATUS.LIMITED_APPROVAL,
  [OBJECT_MODERATION_HUMAN_REVIEW.PRIVATE]:
    OBJECT_MODERATION_REVIEW_STATUS.PRIVATE,
  [OBJECT_MODERATION_HUMAN_REVIEW.REJECTION]:
    OBJECT_MODERATION_REVIEW_STATUS.REJECTION,
  [OBJECT_MODERATION_HUMAN_REVIEW.NOT_SET]:
    OBJECT_MODERATION_REVIEW_STATUS.UNREVIEWED,
};
