import { useReviewModal } from 'src/components/modules/Review/components/ReviewForm/useReviewModal';
import { useQrCodeModal } from 'src/components/shared/QrCodeUrlPreviewer/QrCodeModal/useQrCodeModal';
import useModalStore from 'src/lib/stores/useModalStore';
import { GameStatus } from 'src/lib/types/game';
import { AdminListGamesRow } from 'src/lib/types/gameRow';
import ChangeGameStatusView from '../../view/ChangeStatus';
import DeleteGameView from '../../view/Delete';
import UpdateGameView from '../../view/Update';

export default function useGamesTable() {
  const openModal = useModalStore((state) => state.openModal);

  const handleOpenUpdateModal = (game: AdminListGamesRow) => {
    openModal(<UpdateGameView game={game} />);
  };
  const handleOpenDeleteModal = (uuid: string) => {
    openModal(<DeleteGameView uuid={uuid} />);
  };
  const handleChangeGameStatus = async (
    gameUuid: string,
    status: GameStatus
  ) => {
    openModal(<ChangeGameStatusView uuid={gameUuid} status={status} />);
  };

  const { handleOpenQrCodeModal } = useQrCodeModal();
  const { handleOpenReviewModal } = useReviewModal();

  return {
    handleOpenUpdateModal,
    handleOpenDeleteModal,
    handleChangeGameStatus,
    handleOpenQrCodeModal,
    handleOpenReviewModal,
  };
}
