import { OBJECT_MODERATION_HUMAN_REVIEW } from 'src/lib/types/objectModeration';

export const tooltipTextByHumanReview: Record<
  OBJECT_MODERATION_HUMAN_REVIEW,
  string
> = {
  [OBJECT_MODERATION_HUMAN_REVIEW.FULL_APPROVAL]:
    'Full Approval: Quality content, suitable to be featured and promoted.',
  [OBJECT_MODERATION_HUMAN_REVIEW.LIMITED_APPROVAL]:
    'Limited Approval: Content follows all the rules but is not feature-worthy.',
  [OBJECT_MODERATION_HUMAN_REVIEW.PRIVATE]:
    'Private: Content is not visible to the public.',
  [OBJECT_MODERATION_HUMAN_REVIEW.REJECTION]:
    'Rejection: Content that is problematic due to one of the issues listed in the rejection reason.',
  [OBJECT_MODERATION_HUMAN_REVIEW.NOT_SET]:
    'Not set: Only considers the result of the IA Review.',
};

type StatusAndActionablesKeys =
  | 'humanAnalysisNeeded'
  | 'reviewStatus'
  | 'visibility'
  | 'visibleStatus'
  | 'social'
  | 'editability'
  | 'transfer'
  | 'monetization'
  | 'humanReview';

export const toolTipByStatusAndActionables: Record<
  StatusAndActionablesKeys,
  string[]
> = {
  humanAnalysisNeeded: [
    'Human Analysis Needed: Content needs to be reviewed by a human.',
  ],
  reviewStatus: ['Review Status: Content is under review.'],
  visibility: [
    'Everywhere: Anyone can see it and it can appear on any screen in the app.',
    'Limited: Anyone can see it. Does not appear on screens with featured content (home, explore).',
    'Private: Visible only to interested parties (creators, admins, owners, members, followers, friends).',
    'Hidden: Only the creator and admins can see it.',
    'Not visible: Not visible anywhere in the app. Deleted, blocked and inactive objects.',
  ],
  visibleStatus: ['Visible Status: Content is visible to the public.'],
  humanReview: Object.values(tooltipTextByHumanReview),

  social: [
    `Enable social features, including share object in clubs and private chats`,
    `Nowadays we don't have other social features inside objects`,
  ],
  editability: ['Creator and admins can edit'],
  transfer: ['Owner can transfer ownership'],
  monetization: ['Owner can profit from object'],
};
