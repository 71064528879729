import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import { User } from 'src/lib/types/users';
import { AssetCollectionFormValues } from './types';
import useGameFormView from './useView';

type Props = {
  defaultValues: AssetCollectionFormValues;
  defaultUser?: User;
  onSubmit: (values: AssetCollectionFormValues) => Promise<void>;
  title: string;
};

export default function AssetCollectionForm({
  defaultValues,
  title,
  onSubmit,
  defaultUser,
}: Props): JSX.Element {
  const {
    form: { control, handleSubmit, setValue },
    isSubmitting,
    handleCloseModal,
    UserSearchComponent,
    userSearchProps,
    isPro,
  } = useGameFormView({ defaultValues, defaultUser });

  return (
    <Modal open onClose={handleCloseModal}>
      <Card
        sx={{
          width: '600px',
        }}
      >
        <CardHeader title={title} />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack mb={2} spacing={1.5}>
              <Stack mb={2} spacing={1.5}>
                <ControllerField
                  control={control}
                  name="name"
                  label="Name"
                  fullWidth
                />
                <ControllerField
                  control={control}
                  name="description"
                  label="Description"
                  fullWidth
                  multiline
                  rows={4}
                />
                <UserSearchComponent {...userSearchProps} />

                <Box p={1}>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Is Pro?
                  </FormLabel>
                  <Controller
                    name="isPro"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup
                        row
                        aria-labelledby="row-radio-buttons-group-label"
                        {...field} // Conecta o RadioGroup ao `react-hook-form`
                      >
                        <FormControlLabel
                          value
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                </Box>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1}
                mt={2}
              >
                <Button color="error" onClick={handleCloseModal} size="large">
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={isSubmitting}
                  size="large"
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
