import { GameMakerAsset } from 'src/lib/types/gameMakerAsset';
import useSWR, { SWRResponse } from 'swr';

export const fetchAsset = (uuid: string): SWRResponse<GameMakerAsset> => {
  const response = useSWR<GameMakerAsset, Error>(
    `admin/game-maker-asset/${uuid}`
  );

  return response;
};
