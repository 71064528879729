import { debounce } from 'lodash';
import { useCallback, useEffect } from 'react';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { AssetCollectionEmitter } from '../../events/AssetCollectionEmitter';
import { fetchAssetCollections } from '../../services/fetchAssetCollections';
import CreateAssetCollectionView from '../Create';

export default function useGameMakerAssetCollections() {
  const openModal = useModalStore((state) => state.openModal);

  const { addParam, removeParam, allSearchParams } =
    useURLSearchParams(initialParams);
  function handleChangeSearch(value: string, param: string = 'query') {
    if (!value) removeParam(param);
    else addParam(param, value);
  }

  const handleOpenCreateModal = () => {
    openModal(<CreateAssetCollectionView />);
  };

  const debouncedSearch = debounce(handleChangeSearch, 1000);

  const params = {
    page: +(allSearchParams.page ?? initialParams.page),
    size: +(allSearchParams.size ?? initialParams.size),
    orderBy: allSearchParams.orderBy ?? initialParams.orderBy,
    sortOrder: allSearchParams.order ?? initialParams.order,
    searchBy: allSearchParams.searchBy ?? initialParams.searchBy,
    query: allSearchParams.query ?? initialParams.query,
  };

  const { data, isLoading, mutate } = fetchAssetCollections({
    page: params.page,
    size: params.size,

    ...(params.orderBy &&
      params.sortOrder && {
        order: {
          [params.orderBy]: params.sortOrder,
        },
      }),

    filter: {
      ...(params.searchBy &&
        params.query && {
          [params.searchBy]: {
            like: `${params.query}%`,
          },
        }),
    },
  });

  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    AssetCollectionEmitter.on('AssetCollectionCreated', refetchData);
    AssetCollectionEmitter.on('AssetCollectionUpdated', refetchData);
    AssetCollectionEmitter.on('AssetCollectionDeleted', refetchData);
    return () => {
      AssetCollectionEmitter.off('AssetCollectionCreated', refetchData);
      AssetCollectionEmitter.off('AssetCollectionUpdated', refetchData);
      AssetCollectionEmitter.off('AssetCollectionDeleted', refetchData);
    };
  }, [refetchData]);

  return {
    data,
    isLoading,
    params,
    debouncedSearch,
    addParam,
    removeParam,
    allSearchParams,
    handleOpenCreateModal,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'asc',
  orderBy: 'viewOrder',
  searchBy: 'name',
  query: '',
};

export type FetchGamesQueryParams = {
  page: number;
  size: number;
  order: string;
  orderBy: string;
  searchBy: string;
  query: string;
};
