import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { Game } from 'src/lib/types/game';
import { getMessageFromError } from 'src/lib/utils/error';

export type UpdateAssetCollectionInput = {
  name: string;
  description?: string;
  creatorUuid: string;
};

export async function UpdateAssetCollection(
  uuid: string,
  data: UpdateAssetCollectionInput
): Promise<ServiceResponse<Game>> {
  try {
    const api = await getAPIClient();
    const res = await api.put(
      `admin/game-maker-asset-collection/${uuid}`,
      data
    );
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
