/* eslint-disable @typescript-eslint/no-shadow */
import { AvailableAssets } from './availableAssets';
import { Collection } from './collection';
import { Networks } from './networks';
import { NFT } from './nft';
import { User } from './users';

export type NetworkStatusType = 'processing' | 'consolidated' | 'network_error';
export type TransactionObjectProvider =
  | 'stickers'
  | 'gamification'
  | 'eth'
  | 'apple'
  | 'marketplace'
  | 'transfer'
  | 'google';

export type PaymentRequestStatuses =
  | 'requested'
  | 'canceled'
  | 'executed'
  | 'scheduled';

export type TransactionTypesType =
  | 'recharge'
  | 'buy_sticker'
  | 'payment_request'
  | 'mint_sticker'
  | 'sell_sticker'
  | 'import_sticker'
  | 'gas_charge'
  | 'service_fee'
  | 'refund_coins'
  | 'refund_mint_passes'
  | 'refund_nfts'
  | 'asset_convertion'
  | 'coins_bonification'
  | 'nft_bonification'
  | 'create_collection'
  | 'create_art';

export interface IPaymentRequest {
  uuid: string;
  userUuid: string;
  amount: number; // in coins
  currency: string;
  value: number; // in USD
  status: PaymentRequestStatuses;
  paypalEmail?: string;
}

export type NftPack = {
  nfts: number;
  categoryUuid?: string;
  objectProductFullId?: string;
  objectId?: string;
};

export enum TransactionTypes {
  Mint = 'Mint',
  Buy = 'Buy',
  Sell = 'Sell',
  Transfer = 'Transfer',
  Burn = 'Burn',
  NFT = 'NFT',
}

export type TransactionPoolStatus =
  | 'pending'
  | 'success'
  | 'failed'
  | 'rollback';

export type TransactionHistoryTypes =
  | 'service_fee'
  | 'create_collection'
  | 'mint_nft'
  | 'transfer_nft'
  | 'place_bid'
  | 'cancel_bid'
  | 'match_order_failed'
  | 'sell_nft'
  | 'buy_nft'
  | 'match_order'
  | 'paid_coins_delivered'
  | 'nft_pack_delivered'
  | 'admin_bonus'
  | 'daily_bonus'
  | 'gamification_bonus'
  | 'import_nft'
  | 'payment_request_creation'
  | 'payout';

export type BaseTransactionForHistory = {
  createdAt: number;
  updatedAt: number;
  status: TransactionPoolStatus;
  poolUuid: string;
  type: TransactionHistoryTypes;
};

export type TransactionsPoolType = {
  uuid: string;
  name: TransactionPoolTypeName;
  description: string | null;
  version: number;
  expectedTransactionsType: unknown;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export type WithTransactionsPoolType<T> = T & {
  transactionPoolType: TransactionsPoolType;
};

export type TransactionPoolTypeName =
  | 'bonus_coins_givenV1'
  | 'paid_coins_deliveredV1'
  | 'nft_pack_stickyV1'
  | 'buy_now_stickyV1'
  | 'service_feeV1'
  | 'mint_nft_blockchainV1'
  | 'import_nftV1'
  | 'buy_nft_stickyV1'
  | 'buy_nft_blockchainV1'
  | 'transfer_nft_stickyV1'
  | 'transfer_nft_blockchainV1'
  | 'payoutV1'
  | 'payment_requestV1'
  | 'rejected_payoutV1'
  | 'cancelled_payoutV1'
  | 'refund_coinsV1'
  | 'refund_nft_soldV1'
  | 'refund_nft_not_soldV1'
  | 'bonus_coin_expiryV1'
  | 'create_coinsV1'
  | 'burn_coinsV1'
  | 'new_collection_stickyv1'
  | 'new_collection_blockchainv1'
  | 'new_nft_stickyv1'
  | 'place_bid_stickyv1'
  | 'place_bid_blockchainV1'
  | 'match_order_stickyV1'
  | 'match_order_blockchainV1'
  | 'mint_bundle_blockchainV1'
  | 'cancel_bidV1'
  | 'ownership_migration_from_old_transaction'
  | 'cancel_askV1'
  | 'cancel_crypto_bidV1'
  | 'cancel_crypto_askV1'
  | 'starting_balanceV1'
  | 'update_bidV1'
  | 'upgrade_bonus'
  | 'update_nftV1'
  | 'burn_stickyV1'
  | 'avatar_maker_stickyV1'
  | 'buy_now_blockchainV1'
  | 'fix_negative_earningsV1'
  | 'import_nftV2'
  | 'pro_subscriptionV1'
  | 'nft_pack_bundleV2'
  | 'nft_pack_subscriptionV2'
  | 'sticky_coin_subscriptionV1'
  | 'sticky_coin_bundleV1'
  | 'nft_pack_givenV2'
  | 'starting_balance'
  | 'burn_nftV1'
  | 'take_coinsV1'
  | 'pass_subscriptionV1'
  | 'bonus_prompts_givenV1'
  | 'paid_prompts_deliveredV1'
  | 'subscription_promptsV1'
  | 'paid_publish_credits_deliveredV1'
  | 'bonus_publish_credits_givenV1'
  | 'publish_credits_spentV1';

export const TransactionPoolTypeLabel: Record<TransactionPoolTypeName, string> =
  {
    bonus_coins_givenV1: 'Bonus coins given',
    paid_coins_deliveredV1: 'Paid coins delivered',
    nft_pack_stickyV1: 'NFT pack delivered',
    buy_now_stickyV1: 'NFT buy now',
    service_feeV1: 'Service fee',
    mint_nft_blockchainV1: 'Mint NFT Blockchain',
    import_nftV1: 'Import NFT',
    buy_nft_stickyV1: 'Buy NFT Sticky',
    buy_nft_blockchainV1: 'Buy NFT Blockchain',
    transfer_nft_stickyV1: 'Transfer NFT Sticky',
    transfer_nft_blockchainV1: 'Transfer NFT Blockchain',
    payoutV1: 'Payout',
    payment_requestV1: 'Payment request',
    rejected_payoutV1: 'Rejected payout',
    cancelled_payoutV1: 'Cancelled payout',
    refund_coinsV1: 'Refund coins',
    refund_nft_soldV1: 'Refund NFT sold',
    refund_nft_not_soldV1: 'Refund NFT not sold',
    bonus_coin_expiryV1: 'Bonus coin expiry',
    create_coinsV1: 'Create coins',
    burn_coinsV1: 'Burn coins',
    new_collection_stickyv1: 'New Collection Sticky',
    new_collection_blockchainv1: 'New Collection Blockchain',
    new_nft_stickyv1: 'New NFT Sticky',
    place_bid_stickyv1: 'Place bid Sticky',
    place_bid_blockchainV1: 'Place bid Blockchain',
    match_order_stickyV1: 'Match Order Sticky',
    match_order_blockchainV1: 'Match order Blockchain',
    mint_bundle_blockchainV1: 'Mint bundle Blockchain',
    cancel_bidV1: 'Cancel bid',
    ownership_migration_from_old_transaction:
      'Ownership migration from old transaction',
    cancel_askV1: 'Cancel ask',
    cancel_crypto_bidV1: 'Cancel crypto bid',
    cancel_crypto_askV1: 'Cancel crypto ask',
    starting_balanceV1: 'Starting balance',
    update_bidV1: 'Update bid',
    upgrade_bonus: 'Upgrade bonus',
    update_nftV1: 'Update NFT',
    burn_stickyV1: 'Burn Sticky',
    avatar_maker_stickyV1: 'Avatar maker Sticky',
    buy_now_blockchainV1: 'Buy Now Blockchain',
    fix_negative_earningsV1: 'Fix negative earnings',
    import_nftV2: 'Import NFT V2',
    pro_subscriptionV1: 'Pro Subscription',
    nft_pack_bundleV2: 'NFT Pack Bundle',
    nft_pack_subscriptionV2: 'NFT Pack subscription',
    sticky_coin_subscriptionV1: 'Sticky coin subscription',
    nft_pack_givenV2: 'NFT Pack Given',
    sticky_coin_bundleV1: 'Sticky Coin Bundle',
    starting_balance: 'Starting balance',
    burn_nftV1: 'Burn NFT V1',
    take_coinsV1: 'Confiscation',
    pass_subscriptionV1: 'Pass Subscription',
    bonus_prompts_givenV1: 'Bonus Prompts Given',
    paid_prompts_deliveredV1: 'Paid Prompts Delivered',
    subscription_promptsV1: 'Subscription Prompts',
    paid_publish_credits_deliveredV1: 'Paid Publish Credits Delivered',
    bonus_publish_credits_givenV1: 'Bonus Publish Credits Given',
    publish_credits_spentV1: 'Publish Credits Spent',
  };

export const transactionPoolTypes = Object.values({
  avatar_maker_stickyV1: 'avatar_maker_stickyV1',
  bonus_coin_expiryV1: 'bonus_coin_expiryV1',
  bonus_coins_givenV1: 'bonus_coins_givenV1',
  burn_coinsV1: 'burn_coinsV1', //! Not being used
  burn_nftV1: 'burn_nftV1',
  burn_stickyV1: 'burn_stickyV1', //! Not being used
  buy_nft_blockchainV1: 'buy_nft_blockchainV1', //! Not being used
  nft_pack_givenV3: 'nft_pack_givenV3',
  buy_nft_stickyV1: 'buy_nft_stickyV1', //! Not being used
  buy_now_stickyV1: 'buy_now_stickyV1',
  buy_now_blockchainV1: 'buy_now_blockchainV1',
  cancel_askV1: 'cancel_askV1', //! Not being used
  cancel_bidV1: 'cancel_bidV1',
  cancel_crypto_askV1: 'cancel_crypto_askV1', //! Not being used
  cancel_crypto_bidV1: 'cancel_crypto_bidV1',
  cancelled_payoutV1: 'cancelled_payoutV1',
  create_coinsV1: 'create_coinsV1',
  fix_negative_earningsV1: 'fix_negative_earningsV1',
  import_nftV1: 'import_nftV1',
  import_nftV2: 'import_nftV2',
  match_order_blockchainV1: 'match_order_blockchainV1',
  match_order_stickyV1: 'match_order_stickyV1',
  mint_bundle_blockchainV1: 'mint_bundle_blockchainV1',
  mint_nft_blockchainV1: 'mint_nft_blockchainV1',
  new_collection_blockchainv1: 'new_collection_blockchainv1',
  new_collection_stickyv1: 'new_collection_stickyv1',
  new_nft_stickyv1: 'new_nft_stickyv1',
  nft_pack_stickyV1: 'nft_pack_stickyV1', // First implementation, made by Freixinho. Alters user wallet.
  ownership_migration_from_old_transaction:
    'ownership_migration_from_old_transaction',
  paid_coins_deliveredV1: 'paid_coins_deliveredV1',
  payment_requestV1: 'payment_requestv1',
  payoutV1: 'payoutV1',
  place_bid_blockchainV1: 'place_bid_blockchainV1',
  place_bid_stickyv1: 'place_bid_stickyv1',
  refund_coinsV1: 'refund_coinsV1',
  refund_nft_not_soldV1: 'refund_nft_not_soldV1',
  refund_nft_soldV1: 'refund_nft_soldV1',
  rejected_payoutV1: 'rejected_payoutV1',
  service_feeV1: 'service_feeV1',
  starting_balance: 'starting_balanceV1',
  transfer_nft_blockchainV1: 'transfer_nft_blockchainV1',
  transfer_nft_stickyV1: 'transfer_nft_stickyV1',
  update_bidV1: 'update_bidV1', //! Deprecated
  update_nftV1: 'update_nftV1',
  pro_subscriptionV1: 'pro_subscriptionV1',
  coin_expirationV1: 'coin_expirationV1',
  register_in_game_contestV1: 'register_in_game_contestV1',
  game_contest_prize_distributionV1: 'game_contest_prize_distributionV1',
  revert_user_poolsV1: 'revert_user_poolsV1',
  cancel_all_user_crypto_bidsV1: 'cancel_all_user_crypto_bidsV1',
  cancel_all_user_bidsV1: 'cancel_all_user_bidsV1',
  admin_transfer_nft_v1: 'admin_transfer_nft_v1',
  chest_award_distribution_v1: 'chest_award_distribution_v1',
  shard_conversion_v1: 'shard_conversion_v1',
  nft_pack_bundleV2: 'nft_pack_bundleV2', // Second implementation, made by Leonardo. Only generates a nft pack delivery row. Does not alter wallet.
  nft_pack_subscriptionV2: 'nft_pack_subscriptionV2',
  sticky_coin_subscriptionV1: 'sticky_coin_subscriptionV1',
  sticky_coin_bundleV1: 'sticky_coin_bundleV1',
  nft_pack_givenV2: 'nft_pack_givenV2', // Second implementation, made by Leonardo. Consumes a nft pack delivery row. Alters wallet.
  take_coinsV1: 'take_coinsV1',
  pass_subscriptionV1: 'pass_subscriptionV1',
  bonus_prompts_givenV1: 'bonus_prompts_givenV1',
  paid_prompts_deliveredV1: 'paid_prompts_deliveredV1',
  subscription_promptsV1: 'subscription_promptsV1',
  paid_publish_credits_deliveredV1: 'paid_publish_credits_deliveredV1',
  bonus_publish_credits_givenV1: 'bonus_publish_credits_givenV1',
  publish_credits_spentV1: 'publish_credits_spentV1',
});

export type Transaction = {
  uuid: string;
  poolId: string;
  type: string;
  status: TransactionPoolStatus;
  network: Networks;
  amount: number;
  assetType: AvailableAssets;
  assetUuid?: string | null;
  eventType?: string | null;
  eventUuid: string | null;
  error?: TransactionErrors;
  createdAt: number;
  updatedAt: number;
  finishedAt: number | null;

  userFrom?: User;
  userTo?: User;
  nft?: NFT;
  collection?: Collection;
};

export enum TransactionErrors {
  StickyCoinsToNonStickyWallet = 'StickyCoinsToNonStickyWallet',
  NoStickySystemWallet = 'NoStickySystemWallet',
  BlockchainTimeout = 'BlockchainTimeout',
  LocalTimeout = 'LocalTimeout',
}

export enum TransactionType {
  //! IMPORTANT
  //! When adding a new TransactionType that transactions coins, remember to update the Wallets/updateWalletFromTransaction/index.ts usecase if needed.
  //! Also, remember to update the walletBalanceVerifierQuery.ts query if needed as well.
  //* Sticky Coin Transactions:
  // => user is only "from"
  fee_payment = 'fee_payment',
  fee_payment_rollback = 'fee_payment_rollback', // to
  gas_charge = 'gas_charge',
  gas_charge_rollback = 'gas_charge_rollback', // to
  refund_coins = 'refund_coins',
  refund_coins_rollback = 'refund_coins_rollback', // to
  take_coins = 'take_coins', //! TODO update Wallets/updateWalletFromTransaction/index.ts and walletBalanceVerifierQuery.ts
  take_coins_rollback = 'take_coins_rollback',
  payout = 'payout',
  payout_rollback = 'payout_rollback', // to

  // => user is only "to"
  bonus_recharge = 'bonus_recharge',
  bonus_recharge_rollback = 'bonus_recharge_rollback', // from
  paid_recharge = 'paid_recharge',
  paid_recharge_rollback = 'paid_recharge_rollback', // from

  // => user can be either "from" or "to", but only one at a time.
  nft_payment = 'nft_payment',
  nft_payment_rollback = 'nft_payment_rollback',

  // => user is both "from" and "to" at the same time.
  cancelled_payout = 'cancelled_payout',
  cancelled_payout_rollback = 'cancelled_payout_rollback',
  lock_balance = 'lock_balance',
  rejected_payout = 'rejected_payout',
  rejected_payout_rollback = 'rejected_payout_rollback',
  unlock_balance = 'unlock_balance',
  expire_coins = 'expire_coins',
  expire_coins_rollback = 'expire_coins_rollback',

  // specific to sticky institutional wallet
  coin_emission = 'coin_emission',

  //* End of Sticky Coin Transactions. Below this line there is only transactions related to other assets.
  //* ***************************************************************************************************** */
  //
  // * Prompt-related transactions
  prompt_paid_recharge = 'prompt_paid_recharge',
  prompt_bonus_recharge = 'prompt_bonus_recharge',

  // * Publish Credit related transactions
  publish_credit_paid_recharge = 'publish_credit_paid_recharge',
  publish_credit_bonus_recharge = 'publish_credit_bonus_recharge',
  publish_credit_spent = 'publish_credit_spent',
  //
  // Other Assets transactions:
  create_collection_blockchain = 'create_collection_blockchain',
  create_collection_blockchain_rollback = 'create_collection_blockchain_rollback',
  create_collection_sticky = 'create_collection_sticky',
  create_collection_sticky_rollback = 'create_collection_sticky_rollback',
  import_nft = 'import_nft',
  mint_nft_blockchain = 'mint_nft_blockchain',
  mint_nft_blockchain_rollback = 'mint_nft_blockchain_rollback',
  mint_nft_sticky = 'mint_nft_sticky',
  mint_nft_sticky_rollback = 'mint_nft_sticky_rollback',
  ownership_migration_from_old_transaction = 'ownership_migration_from_old_transaction',
  transfer_nft_blockchain = 'transfer_nft_blockchain',
  transfer_nft_sticky = 'transfer_nft_sticky',
  transfer_nft_sticky_rollback = 'transfer_nft_sticky_rollback',
  transfer_nft_shard = 'transfer_nft_shard',
  nft_shard_emission = 'nft_shard_emission',
  nft_edition_emission = 'nft_edition_emission',
  subscribed = 'subscribed',
  purchase = 'purchase',
  subscribed_rollback = 'subscribed_rollback',
  register_in_game_contest_event = 'register_in_game_contest_event',
  game_contest_event_prize_distribution = 'game_contest_event_prize_distribution',
}
