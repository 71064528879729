import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { capitalCase } from 'change-case';
import Tooltip from 'src/components/shared/Tooltip';
import {
  OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED,
  ObjectActionablesStatus,
} from 'src/lib/types/objectModeration';
import Label from 'src/template/components/Label';
import { getStatusesAndActionablesByModerationStatus } from '../../utils/objectStatusAndActionables';
import { toolTipByStatusAndActionables } from '../../utils/statusAndActionablesTooltipsText';
import {
  colorByObjectVisibility,
  colorByReviewStatus,
  colorByUserStatus,
  colorByVisibleStatus,
  colortByActionablesStatus,
} from '../../utils/utils';
import { StatusAndActionablesCardProps } from './types';

function StatusAndActionablesCard(props: StatusAndActionablesCardProps) {
  const { adminStatus, reviewStatus, userStatus, objectType } = props;

  // Obtem o status e as ações disponíveis
  const statusAndActionables = getStatusesAndActionablesByModerationStatus(
    reviewStatus,
    userStatus,
    adminStatus,
    objectType
  );

  const {
    visibleStatus,
    visibility,
    editability,
    monetization,
    social,
    transfer,
    humanAnalysisNeeded,
  } = statusAndActionables;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row">
                <Typography variant="body1">
                  <strong>Review Status:</strong>
                </Typography>
              </Stack>
              <Label variant="ghost" color={colorByReviewStatus[reviewStatus]}>
                {capitalCase(reviewStatus)}
              </Label>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row">
                <Typography variant="body1">
                  <strong>User Status:</strong>
                </Typography>
              </Stack>
              <Label variant="ghost" color={colorByUserStatus[userStatus]}>
                {capitalCase(userStatus)}
              </Label>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row">
                <Typography variant="body1">
                  <strong>Visible Status:</strong>
                </Typography>

                <Tooltip title={toolTipByStatusAndActionables.visibleStatus} />
              </Stack>
              <Label
                variant="ghost"
                color={colorByVisibleStatus[visibleStatus]}
              >
                {capitalCase(visibleStatus)}
              </Label>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row">
                <Typography variant="body1">
                  <strong>Visibility:</strong>
                </Typography>
                <Tooltip title={toolTipByStatusAndActionables.visibility} />
              </Stack>
              <Label
                variant="ghost"
                color={colorByObjectVisibility[visibility]}
              >
                {capitalCase(visibility)}
              </Label>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row">
                <Typography variant="body1">
                  <strong>HA Needed?:</strong>
                </Typography>
                <Tooltip
                  title={toolTipByStatusAndActionables.humanAnalysisNeeded}
                />
              </Stack>
              <Label
                variant="ghost"
                color={
                  humanAnalysisNeeded !==
                  OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE
                    ? 'error'
                    : 'success'
                }
              >
                {humanAnalysisNeeded !==
                OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE
                  ? 'Yes'
                  : 'No'}
              </Label>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <ActionableComponent title="editability" value={editability} />

            <ActionableComponent title="social" value={social} />

            <ActionableComponent title="transfer" value={transfer} none />

            <ActionableComponent
              title="monetization"
              value={monetization}
              none
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function ActionableComponent(props: {
  title: 'social' | 'editability' | 'transfer' | 'monetization';
  value: ObjectActionablesStatus;
  tooltip?: string;
  none?: boolean;
}) {
  const { title, value, none = false } = props;
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Stack direction="row">
        <Typography variant="body1">
          <strong>{capitalCase(title)}:</strong>
        </Typography>
        <Tooltip title={toolTipByStatusAndActionables[title]} />
      </Stack>
      <Label
        variant="ghost"
        color={none ? 'default' : colortByActionablesStatus[value]}
      >
        {none ? 'None' : capitalCase(value ?? 'null')}
      </Label>
    </Box>
  );
}

export default StatusAndActionablesCard;
