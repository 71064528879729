import { ContentCopy, Delete, Edit } from '@mui/icons-material';
import {
  Avatar,
  Box,
  MenuItem,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import CopyToClipboard from 'react-copy-to-clipboard';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import Loader from 'src/components/shared/Loader';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { GameMakerAssetCollection } from 'src/lib/types/gameMakerAsset';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';
import useAssetCollectionTable from './useView';

type AssetCollectionTableQueryParams = {
  page: number;
  size: number;
  orderBy: string;
  sortOrder: string;
};

type AssetCollectionTableProps = {
  data?: GameMakerAssetCollection[];
  totalCount?: number;
  isLoading: boolean;
  params: AssetCollectionTableQueryParams;
};

export default function AssetCollectionTable({
  data,
  isLoading,
  params: { page, size, orderBy, sortOrder },
  totalCount,
}: AssetCollectionTableProps) {
  const { addParam, removeParam } = useURLSearchParams();
  const {
    handleOpenDeleteModal,
    handleOpenUpdateModal,
    handleToggleActiveStatus,
    isChangingStatus,
    rowUuidChangingStatus,
    updatingRowId,
    processRowUpdate,
    handleProcessRowUpdateError,
  } = useAssetCollectionTable();

  return (
    <MagicTable
      rowHeight={64}
      loading={isLoading}
      pageSize={size}
      page={page}
      paginationMode="server"
      onPageChange={(newPage) => {
        addParam('page', newPage);
      }}
      onPageSizeChange={(newSize) => {
        addParam('size', newSize);
      }}
      onSortModelChange={(model) => {
        const currentModel = model[0];
        if (currentModel) {
          addParam('orderBy', currentModel.field);
          if (currentModel.sort) addParam('order', currentModel.sort);
        } else {
          removeParam('order');
          removeParam('orderBy');
        }
      }}
      sortModel={[
        {
          field: orderBy,
          sort: sortOrder as GridSortDirection,
        },
      ]}
      rows={data ?? []}
      rowCount={totalCount ?? undefined}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={handleProcessRowUpdateError}
      experimentalFeatures={{ newEditingApi: true }}
      columns={[
        {
          field: 'active',
          headerName: 'Status',
          flex: 1 / 2,
          renderCell({ row }) {
            const assetCollectionUuid = row?.uuid;
            const status = row?.active;
            return (
              <Stack alignItems="center" justifyContent="center" spacing={0}>
                <Switch
                  checked={status}
                  color="success"
                  onChange={() => handleToggleActiveStatus(assetCollectionUuid)}
                  size="small"
                  disabled={
                    (isChangingStatus &&
                      rowUuidChangingStatus === assetCollectionUuid) ||
                    isLoading
                  }
                />
                <Box textAlign="center" fontSize={12}>
                  (
                  {assetCollectionUuid &&
                  rowUuidChangingStatus === assetCollectionUuid
                    ? 'Changing...'
                    : status
                    ? 'Active'
                    : 'Inactive'}
                  )
                </Box>
              </Stack>
            );
          },
        },
        {
          field: 'viewOrder',
          headerName: 'Order',
          editable: true,
          flex: 0,
          type: 'number',
          disableReorder: true,
          renderCell({ row }) {
            const isUpdating = updatingRowId === row.uuid;
            return (
              <Flex alignItems="center" justifyContent="center" gap={2}>
                <Typography variant="body2" color="text.secondary">
                  {row.viewOrder}
                </Typography>
                {isUpdating && <Loader size={10} />}
              </Flex>
            );
          },
        },
        {
          field: 'creator',
          headerName: 'Creator',
          sortable: false,
          renderCell({ row }) {
            return <MagicTableUserCell user={row.creator} />;
          },
        },
        {
          field: 'name',
          headerName: 'Name',
          renderCell({ row }) {
            return (
              <Flex component="span" gap={2}>
                <Avatar
                  alt={row.name ?? 'Asset Collection'}
                  src={row.assets?.[0]?.url ?? ''}
                  variant="square"
                />
                <Stack>
                  <Link
                    to={Routes.sgm.assetCollection(row.uuid)}
                    color={row.name ? 'inherit' : 'GrayText'}
                    underline="hover"
                  >
                    {row.name}
                  </Link>
                </Stack>
              </Flex>
            );
          },
        },
        {
          field: 'isPro',
          headerName: 'Is Pro',
          flex: 0,
          renderCell({ value }) {
            return (
              <Label color={value ? 'success' : 'error'}>
                {value ? 'Yes' : 'No'}
              </Label>
            );
          },
        },
        {
          field: 'assetsCount',
          headerName: 'Assets Count',
          flex: 1 / 2,
        },
        {
          field: 'description',
          headerName: 'Description',
          valueFormatter({ value }) {
            return value || '-';
          },
        },
        {
          field: 'updatedAt',
          headerName: 'Updated At',
          sortingOrder: ['desc', 'asc'],
          flex: 1 / 2,
          valueFormatter({ value }) {
            return value ? formatUTCDate(value) : '-';
          },
        },
        {
          field: 'createdAt',
          headerName: 'Created At',
          sortingOrder: ['desc', 'asc'],
          flex: 1 / 2,
          valueFormatter({ value }) {
            return value ? formatUTCDate(value) : '-';
          },
        },
        {
          field: 'actions',
          headerName: 'Actions',
          align: 'center',
          sortable: false,
          flex: 0,
          renderCell({ row }) {
            return (
              <MoreMenu>
                <CopyToClipboard text={row.uuid}>
                  <MenuItem aria-label="copy feed uuid">
                    <ContentCopy />
                    Copy UUID
                  </MenuItem>
                </CopyToClipboard>
                <MenuItem
                  onClick={() => {
                    handleOpenUpdateModal(row);
                  }}
                >
                  <Edit />
                  Update
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleOpenDeleteModal(row.uuid);
                  }}
                >
                  <Delete />
                  Delete
                </MenuItem>
              </MoreMenu>
            );
          },
        },
      ]}
    />
  );
}
