import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useUserSearch } from 'src/components/shared/Form/useUserSearch';
import useModalStore from 'src/lib/stores/useModalStore';
import { GameMakerAssetCollection } from 'src/lib/types/gameMakerAsset';
import { User } from 'src/lib/types/users';
import * as yup from 'yup';
import { AssetCollectionFormValues } from './types';

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  creatorUuid: yup.string().required('Creator is required'),
});

function useAssetCollectionFormView({
  defaultValues,
  assetCollection,
  defaultUser,
}: {
  defaultValues: AssetCollectionFormValues;
  assetCollection?: GameMakerAssetCollection;
  defaultUser?: User;
}) {
  const onClose = useModalStore((state) => state.closeModal);
  const form = useForm<AssetCollectionFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    formState: { isSubmitting },
  } = form;

  const handleCloseModal = () => {
    onClose();
  };

  // User search
  const { UserSearchComponent, userSearchProps } = useUserSearch({
    form,
    userUuidField: 'creatorUuid',
    defaultUser,
  });

  const isPro = form.watch('isPro');

  return {
    form,
    isSubmitting,
    handleCloseModal,
    userSearchProps,
    UserSearchComponent,
    isPro,
  };
}

export default useAssetCollectionFormView;
