import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { getMessageFromError } from 'src/lib/utils/error';

export async function UpdateAssetCollectionOrder(
  uuid: string,
  viewOrder: number
): Promise<ServiceResponse<{ viewOrder: number }>> {
  try {
    const api = await getAPIClient();
    const res = await api.patch(
      `admin/game-maker-asset-collection/${uuid}/order`,
      {
        viewOrder,
      }
    );
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
