import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import Flex from 'src/components/shared/Flex';
import SectionDivider from 'src/components/shared/SectionDivider';
import SimpleTable from 'src/components/shared/SimpleTable/SimpleTable';
import { SGMChatStatistics } from 'src/lib/types/sgmChatMessage';
import { formatDollar } from 'src/lib/utils/formatDollar';
import { formatPercentage } from 'src/lib/utils/formatPercentage';

interface MakerStatsCardInfosProps {
  statsData?: SGMChatStatistics;
}

export default function MakerStatsCardInfos({
  statsData,
}: MakerStatsCardInfosProps) {
  const {
    uniqueChats,
    uniqueUsers,
    avgChatsPerUser,
    totalCost,
    avgCostPerUser,
    avgCostPerChat,

    totalChatMessages,
    totalUserMessages,
    totalIAMessages,
    avgMessagePerChat,
    avgCostPerMessage,

    totalPublished,
    publishedPercentage,
    totalMessagesFromPublishedGamesChats,
    totalUserMessagesFromPublishedGamesChats,
    totalIAMessagesFromPublishedGamesChats,
    avgMessagePerPublishedGameChat,

    modelMetrics,
  } = statsData || {};

  return (
    <Stack
      alignItems={'center'}
      justifyContent={'space-evenly'}
      gap={2}
      flexWrap={'wrap'}
    >
      <Flex
        justifyContent={'space-between'}
        gap={2}
        flexWrap={'wrap'}
        sx={{ width: '100%' }}
      >
        <Card sx={{ flexGrow: 1, minWidth: '25%' }}>
          <Flex justifyContent="space-between" alignItems="center">
            <CardHeader title="General" />
          </Flex>
          <CardContent>
            <SimpleTable
              border
              rounded
              data={[
                {
                  id: 'uniqueChats',
                  label: 'Chats',
                  value: uniqueChats ?? '-',
                },
                {
                  id: 'uniqueUsers',
                  label: 'Users',
                  value: uniqueUsers ?? '-',
                },
                {
                  id: 'avgChatsPerUser',
                  label: 'Chat per User',
                  value: avgChatsPerUser ?? '-',
                },
                {
                  id: 'totalCost',
                  label: 'Total Cost',
                  value: formatDollar(totalCost, 2),
                },
                {
                  id: 'avgCostPerUser',
                  label: 'Cost per User',
                  value: formatDollar(avgCostPerUser, 2),
                },
                {
                  id: 'avgCostPerChat',
                  label: 'Cost per Chat',
                  value: formatDollar(avgCostPerChat, 2),
                },
              ]}
            />
          </CardContent>
        </Card>
        <Card sx={{ flexGrow: 1, minWidth: '25%' }}>
          <Flex justifyContent="space-between" alignItems="center" pr={2}>
            <CardHeader title="Messages" />
          </Flex>
          <CardContent>
            <SimpleTable
              border
              rounded
              data={[
                {
                  id: 'totalMessages',
                  label: 'Total Messages',
                  value: totalChatMessages ?? '-',
                },
                {
                  id: 'totalUserMessages',
                  label: 'Sent by User',
                  value: totalUserMessages ?? '-',
                },
                {
                  id: 'totalIAMessages',
                  label: 'Sent by AI',
                  value: totalIAMessages ?? '-',
                },
                {
                  id: 'avgMessagePerChat',
                  label: 'Message per Chat',
                  value: avgMessagePerChat ?? '-',
                },
                {
                  id: 'avgCostPerMessage',
                  label: 'Cost per Message',
                  value: formatDollar(avgCostPerMessage),
                },
              ]}
            />
          </CardContent>
        </Card>
        <Card sx={{ flexGrow: 1, minWidth: '25%' }}>
          <Flex justifyContent="space-between" alignItems="center" pr={2}>
            <CardHeader title="Published games" />
          </Flex>
          <CardContent>
            <SimpleTable
              border
              rounded
              data={[
                {
                  id: 'totalPublished',
                  label: ' Total chats published',
                  value: totalPublished ?? '-',
                },
                {
                  id: 'publishedPercentage',
                  label: '% Chats published',
                  value: formatPercentage(publishedPercentage),
                },
                {
                  id: 'totalMessagesFromPublishedGamesChats',
                  label: 'Total Messages (published chats)',
                  value: totalMessagesFromPublishedGamesChats ?? '-',
                },
                {
                  id: 'totalUserMessagesFromPublishedGamesChats',
                  label: 'Total User Messages (published chats)',
                  value: totalUserMessagesFromPublishedGamesChats ?? '-',
                },
                {
                  id: 'totalIAMessagesFromPublishedGamesChats',
                  label: 'Total AI Messages (published chats)',
                  value: totalIAMessagesFromPublishedGamesChats ?? '-',
                },
                {
                  id: 'avgMessagePerPublishedGameChat',
                  label: 'Message per chat (published chats)',
                  value: avgMessagePerPublishedGameChat ?? '-',
                },
              ]}
            />
          </CardContent>
        </Card>
      </Flex>
      <SectionDivider section={'Stats By Model'} />
      <Flex justifyContent={'space-between'} gap={3} flexWrap={'wrap'}>
        {modelMetrics &&
          Object.entries(modelMetrics).map(([key, modelMetric]) => (
            <Card sx={{ flexGrow: 1, minWidth: '500px' }}>
              <Flex justifyContent="space-between" alignItems="center" pr={2}>
                <CardHeader title={key} />
              </Flex>
              <CardContent>
                <Stack gap={2}>
                  <SimpleTable
                    border
                    rounded
                    capitalize={false}
                    data={[
                      {
                        id: 'uniqueUsers',
                        label: 'Unique Users',
                        value: modelMetric.uniqueUsers ?? '-',
                      },
                      {
                        id: 'totalChats',
                        label: 'Chats',
                        value: modelMetric.chatsThatUsed ?? '-',
                      },
                      {
                        id: 'totalMessages',
                        label: 'User Prompts',
                        value: modelMetric.totalMessages ?? '-',
                      },
                    ]}
                  />

                  <SimpleTable
                    border
                    rounded
                    capitalize={false}
                    data={[
                      {
                        id: 'chatsByUser',
                        label: 'Chats/User',
                        value: modelMetric.chatsByUser ?? '-',
                      },
                      {
                        id: 'messagesByChat',
                        label: 'Prompts/Chat',
                        value: modelMetric.messagesByChat ?? '-',
                      },
                      {
                        id: 'messagesByUser',
                        label: 'Prompts/User',
                        value: modelMetric.messagesByUser ?? '-',
                      },
                    ]}
                  />

                  <SimpleTable
                    border
                    rounded
                    capitalize={false}
                    data={[
                      {
                        id: 'totalPromptTokens',
                        label: 'Input Tokens',
                        value: modelMetric.totalPromptTokens ?? '-',
                      },
                      {
                        id: 'totalCachedTokens',
                        label: 'Cached Tokens',
                        value: modelMetric.totalCachedTokens ?? '-',
                      },
                      {
                        id: 'totalCompletionTokens',
                        label: 'Output Tokens',
                        value: modelMetric.totalCompletionTokens ?? '-',
                      },
                    ]}
                  />

                  <SimpleTable
                    border
                    rounded
                    capitalize={false}
                    data={[
                      {
                        id: 'totalCost',
                        label: 'Total Cost',
                        value: formatDollar(modelMetric.totalCost, 2),
                      },
                    ]}
                  />

                  <SimpleTable
                    border
                    rounded
                    capitalize={false}
                    data={[
                      {
                        id: 'promptTokensByMessage',
                        label: 'Input Tokens/Prompt',
                        value: modelMetric.promptTokensByMessage ?? '-',
                      },
                      {
                        id: 'cachedTokensByMessage',
                        label: 'Cached Tokens/Prompt',
                        value: modelMetric.cachedTokensByMessage ?? '-',
                      },
                      {
                        id: 'completionTokensByMessage',
                        label: 'Output Tokens/Prompt',
                        value: modelMetric.completionTokensByMessage ?? '-',
                      },
                    ]}
                  />

                  <SimpleTable
                    border
                    rounded
                    capitalize={false}
                    data={[
                      {
                        id: 'estimatedTokensCost',
                        label: 'Input Tokens Cost',
                        value: formatDollar(modelMetric.estimatedTokensCost, 2),
                      },
                      {
                        id: 'estimatedCompletionTokensCost',
                        label: 'Output Tokens Cost',
                        value: formatDollar(
                          modelMetric.estimatedCompletionTokensCost,
                          3
                        ),
                      },
                      {
                        id: 'estimatedCachedTokensCost',
                        label: 'Cached Tokens Cost',
                        value: formatDollar(
                          modelMetric.estimatedCachedTokensCost
                        ),
                      },
                    ]}
                  />
                  <SimpleTable
                    border
                    rounded
                    capitalize={false}
                    data={[
                      {
                        id: 'avgCostPerMessage',
                        label: 'Cost/Prompt',
                        value:
                          formatDollar(modelMetric.avgCostPerMessage, 2) ?? '-',
                      },
                      {
                        id: 'avgCostPerChat',
                        label: 'Cost/Chat',
                        value:
                          formatDollar(modelMetric.avgCostPerChat, 2) ?? '-',
                      },
                      {
                        id: 'avgCostPerUser',
                        label: 'Cost/User',
                        value:
                          formatDollar(modelMetric.avgCostPerUser, 2) ?? '-',
                      },
                    ]}
                  />
                </Stack>
              </CardContent>
            </Card>
          ))}
      </Flex>
    </Stack>
  );
}
