import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import useModalStore from 'src/lib/stores/useModalStore';
import Routes from 'src/routes/Routes';
import useTabs from 'src/template/hooks/useTabs';
import { AssetEmitter } from '../../events/AssetEmitter';
import { fetchAsset } from '../../services/fetchAsset';
import UpdateAssetView from '../UpdateAsset';
import { AssetTabs } from './tabs';

const DEFAULT_TAB = AssetTabs.MAIN;

export default function useAssetDetailsView() {
  const { assetUuid } = useParams<'assetUuid'>();
  const navigate = useNavigate();
  if (!assetUuid) navigate(Routes.channel.channels);

  const openModal = useModalStore((state) => state.openModal);

  const handleOpenUpdateModal = () => {
    openModal(<UpdateAssetView asset={assetDetails!} />);
  };

  const { currentTab, onChangeTab } = useTabs(DEFAULT_TAB, {
    resetOnChange: true,
  });

  const { data: assetDetails, mutate } = fetchAsset(assetUuid!);
  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    AssetEmitter.on('AssetUpdated', refetchData);
    return () => {
      AssetEmitter.off('AssetUpdated', refetchData);
    };
  }, [refetchData]);

  return {
    assetDetails,
    currentTab,
    onChangeTab,
    handleOpenUpdateModal,
  };
}
