import { GridRowModel } from '@mui/x-data-grid';
import { useCallback, useState } from 'react';
import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { GameMakerAssetCollection } from 'src/lib/types/gameMakerAsset';
import { AssetCollectionEmitter } from '../../events/AssetCollectionEmitter';
import { toggleAssetCollectionActive } from '../../services/toggleAssetCollectionActive';
import { UpdateAssetCollectionOrder } from '../../services/UpdateAssetCollectionOrder';
import DeleteAssetCollectionView from '../../view/Delete';
import UpdateAssetCollectionView from '../../view/Update';

export default function useAssetCollectionTable() {
  const notify = useNotify();
  const openModal = useModalStore((state) => state.openModal);

  const handleOpenUpdateModal = (assetCollection: GameMakerAssetCollection) => {
    openModal(<UpdateAssetCollectionView assetCollection={assetCollection} />);
  };
  const handleOpenDeleteModal = (uuid: string) => {
    openModal(<DeleteAssetCollectionView uuid={uuid} />);
  };

  // status
  const [rowUuidChangingStatus, setRowUuidChangingStatus] = useState<string>();

  async function handleToggleActiveStatus(uuid: string) {
    setRowUuidChangingStatus(uuid);

    const { error, data } = await toggleAssetCollectionActive(uuid);

    if (error) {
      notify(error, { variant: 'error' });
    } else if (data) {
      notify('Asset collection status updated', { variant: 'success' });

      AssetCollectionEmitter.emit('AssetCollectionUpdated', null);
    }

    setRowUuidChangingStatus(undefined);
  }

  const isChangingStatus = !!rowUuidChangingStatus;

  // View Order

  const [updatingRowId, setUpdatingRowId] = useState<string | null>(null);

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel) => {
      setUpdatingRowId(newRow.uuid); // Set the updating row ID

      const { data: responseData, error } = await UpdateAssetCollectionOrder(
        newRow.uuid,
        newRow.viewOrder as number
      );

      if (error) {
        setUpdatingRowId(null); // Reset the updating row ID on error
        throw new Error(error);
      }

      if (!responseData) {
        setUpdatingRowId(null); // Reset the updating row ID if update fails
        throw new Error('Error, Game Feed Item could not be updated!');
      }

      AssetCollectionEmitter.emit('AssetCollectionUpdated', newRow.uuid);
      notify('Order Updated', { variant: 'success' });

      setUpdatingRowId(null); // Reset the updating row ID on success

      return { ...newRow, ...responseData };
    },
    [UpdateAssetCollectionOrder, notify, AssetCollectionEmitter]
  );

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    setUpdatingRowId(null); // Reset the updating row ID on error
    notify('Error to change order', { variant: 'error' });
    console.error(error);
  }, []);

  return {
    handleOpenUpdateModal,
    handleOpenDeleteModal,
    handleToggleActiveStatus,
    isChangingStatus,
    rowUuidChangingStatus,
    updatingRowId,
    processRowUpdate,
    handleProcessRowUpdateError,
  };
}
