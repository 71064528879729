import { ContentCopy, OpenInNew } from '@mui/icons-material';
import { Box, IconButton, MenuItem, Stack, Tooltip } from '@mui/material';
import { GridColumns, GridSortDirection } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { capitalCase } from 'change-case';
import { format } from 'date-fns';
import CopyToClipboard from 'react-copy-to-clipboard';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MoreMenu from 'src/components/shared/MoreMenu';
import SectionDivider from 'src/components/shared/SectionDivider';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { TransactionHistoryTableType } from 'src/lib/types/TransactionHistory';
import { newDateWith3Hours } from 'src/lib/utils/addHoursToDate';
import { formatFractionNumber } from 'src/lib/utils/formatFractionNumber';
import formatStickyCoinValue from 'src/lib/utils/formatStickyCoinValue';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import {
  setEndDateHours,
  setStartDateHours,
} from 'src/lib/utils/setFiltersDateHour';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Label from 'src/template/components/Label';
import { getLabelColor } from '../../../../../lib/utils/transactions/getTransactionStatusLabelColor';
import { transactionHistoryTabs } from '../../../Profile/sections/TransactionPoolHistory/tabs';
import PoolsFilter from '../../components/List/PoolsFilter';
import { FilterFields } from '../../components/List/types';
import { fetchTransactions } from '../../services/fetchTransactions';

export default function Pools() {
  const { allSearchParams, addParam, removeParams } = useURLSearchParams({
    page: '0',
    size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
    sortBy: 'createdAt',
    sortOrder: 'desc',
  });

  const { sortBy, sortOrder } = allSearchParams;

  const {
    data: transactions,
    total,
    loading,
  } = fetchTransactions({
    page: allSearchParams.page,
    size: allSearchParams.size,
    order: {
      [sortBy!]: sortOrder,
    },
    filter: {
      status: allSearchParams.tab,
      createdAt: {
        gte: allSearchParams[FilterFields.START_DATE],
        lte: allSearchParams[FilterFields.END_DATE],
      },
      'transactionPoolType.name': {
        eq: allSearchParams[FilterFields.POOL_TYPE],
      },
    },
  });

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <HeaderBreadcrumbs
          heading="Transaction Pools"
          links={[{ name: 'Transaction Pools' }]}
        />

        <Stack direction="row" gap={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              value={
                new Date(
                  allSearchParams.startDate ??
                    CommonDateStrings.firstTransactionAndPool.date
                )
              }
              minDate={new Date(CommonDateStrings.firstTransactionAndPool.date)}
              maxDate={newDateWith3Hours()}
              onChange={(e) => {
                if (!e) return;
                addParam('startDate', setStartDateHours(e));
              }}
              format="dd/MM/yyyy"
            />
            <DatePicker
              label="End Date"
              value={new Date(allSearchParams.endDate ?? newDateWith3Hours())}
              minDate={
                new Date(CommonDateStrings.firstRevenueEventV2CreatedAt.date)
              }
              maxDate={newDateWith3Hours()}
              onChange={(e) => {
                if (!e) return;
                addParam('endDate', setEndDateHours(e));
              }}
              format="dd/MM/yyyy"
            />
          </LocalizationProvider>
        </Stack>
      </Stack>
      <SectionDivider section="Dates are in UTC timezone" />

      <MagicTable.Container>
        <MagicTable.Tabs tabs={transactionHistoryTabs} defaultTab="all" />
        <PoolsFilter />
        <MagicTable.Wrapper>
          <MagicTable
            loading={loading}
            pageSize={+allSearchParams.size!}
            page={+allSearchParams.page!}
            rowCount={total}
            paginationMode="server"
            onPageChange={(newPage) => {
              addParam('page', newPage);
            }}
            onPageSizeChange={(newSize) => {
              addParam('size', newSize);
            }}
            onSortModelChange={(model) => {
              const { field, sort } = model[0];
              if (field && sort) {
                addParam('sortOrder', sort);
                addParam('sortBy', field);
              } else {
                removeParams(['sortBy', 'sortOrder']);
              }
            }}
            sortModel={[
              { field: sortBy!, sort: sortOrder as GridSortDirection },
            ]}
            columns={tableColumns}
            rows={transactions ?? []}
            getRowId={(r) => `${r.poolUuid}${r.createdAt}`}
          />
        </MagicTable.Wrapper>
      </MagicTable.Container>
    </>
  );
}

const getPendingValue = (pool: TransactionHistoryTableType) =>
  `pending since ${format(pool.createdAt, 'dd/MM/yyyy')} ${format(
    pool.createdAt,
    'HH:mm'
  )}`;

const tableColumns: GridColumns<TransactionHistoryTableType> = [
  {
    field: 'originalType',
    headerName: 'Pool Type',
    sortable: false,
    flex: 1,
    renderCell({ row }) {
      const value = row.originalType ?? row.type;
      return capitalCase(value ?? '--');
    },
  },

  {
    field: 'asset',
    headerName: 'Asset',
    sortable: false,
    renderCell({ row }) {
      if (row.asset?.id) {
        if (row.assetType) {
          return (
            <Link to={Routes[row.assetType](row.asset.id)}>
              {row.asset?.name ?? '--'}
            </Link>
          );
        }
      }
      return row.asset?.name ?? '--';
    },
  },
  {
    field: 'coins',
    headerName: 'Coins',
    sortable: false,
    flex: 0,
    valueFormatter({ value }) {
      return value ? formatFractionNumber(formatStickyCoinValue(value)) : '--';
    },
  },
  {
    field: 'editions',
    headerName: 'Editions',
    sortable: false,
    flex: 0,
    valueFormatter({ value }) {
      return value ?? '--';
    },
  },
  {
    field: 'shards',
    headerName: 'Shards',
    sortable: false,
    flex: 0,
    valueFormatter({ value }) {
      return value ?? '--';
    },
  },
  {
    field: 'prompts',
    headerName: 'Prompts',
    sortable: false,
    flex: 0,
    valueFormatter({ value }) {
      return value ?? '--';
    },
  },
  {
    field: 'credits',
    headerName: 'Credits',
    sortable: false,
    flex: 0,
    valueFormatter({ value }) {
      return value ?? '--';
    },
  },
  {
    field: 'service',
    headerName: 'Service',
    sortable: false,
    flex: 0,
    valueFormatter({ value }) {
      return value ?? '--';
    },
  },
  {
    field: 'from',
    headerName: 'From',
    sortable: false,
    renderCell({ row }) {
      if (row.from?.userId)
        return (
          <Link to={Routes.user(row.from?.userId)}>
            {row.from?.userName ?? '--'}
          </Link>
        );
      return '--';
    },
  },
  {
    field: 'to',
    headerName: 'To',
    sortable: false,
    renderCell({ row }) {
      if (row.to?.userId)
        return (
          <Link to={Routes.user(row.to?.userId)}>
            {row.to?.userName ?? '--'}
          </Link>
        );
      return '--';
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: false,
    flex: 0,
    renderCell({ row }) {
      return (
        <Tooltip title={row.status === 'pending' ? getPendingValue(row) : ''}>
          <Box textTransform="capitalize">
            <Label color={getLabelColor(row.status)}>{row.status}</Label>
          </Box>
        </Tooltip>
      );
    },
  },
  {
    field: 'hasNote',
    headerName: 'Has Note',
    sortable: false,
    flex: 0,
    renderCell({ row }) {
      return (
        <Label color={row.hasNote ? 'success' : 'primary'}>
          {row.hasNote ? 'Yes' : 'No'}
        </Label>
      );
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    sortComparator: () => 0,
    sortingOrder: ['desc', 'asc'],
    valueFormatter({ value }) {
      return formatUTCDate(value);
    },
  },
  {
    field: 'actions',
    headerName: '',
    align: 'center',
    sortable: false,
    flex: 0,
    renderCell({ row }) {
      return (
        <>
          <IconButton
            component={Link}
            to={Routes.transactions.pool(row.poolId)}
          >
            <OpenInNew />
          </IconButton>
          <MoreMenu>
            <CopyToClipboard text={row.poolId}>
              <MenuItem aria-label="copy feed uuid">
                <ContentCopy />
                Copy UUID
              </MenuItem>
            </CopyToClipboard>
          </MoreMenu>
        </>
      );
    },
  },
];
