// eslint-disable-next-line import/no-cycle
import { User } from './users';

/* eslint-disable @typescript-eslint/naming-convention */
export type ObjectModeration = {
  uuid: string;
  objectType: OBJECT_MODERATION_OBJECT_TYPE;
  objectUuid: string;
  aiModel: string | null;
  aiOutput: Record<string, any> | null;
  aiReview: OBJECT_MODERATION_AI_REVIEW | null;
  aiRejectionReason: string | null;
  aiReviewedAt: Date | null;
  humanReview: OBJECT_MODERATION_HUMAN_REVIEW | null;
  adminReviewerUuid: string | null;
  humanReviewRejectionReason: string | null;
  humanReviewedAt: Date | null;
  internalJustification: string | null;
  reviewNote: string | null;
  reviewStatus: OBJECT_MODERATION_REVIEW_STATUS;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;

  adminReviewer?: User;
};

export enum OBJECT_MODERATION_OBJECT_TYPE {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  NFT = 'nft',
  COLLECTION = 'collection',
  CLUB = 'club',
  USER = 'user',
  CHANNEL = 'channel',
  VIDEO = 'video',
  GAME_VERSION = 'gameVersion',
}

export enum OBJECT_MODERATION_AI_REVIEW {
  AI_APPROVED = 'AiApproved',
  AI_REJECTED = 'AiRejected',
  AI_INCONCLUSIVE = 'AiInconclusive',
  AI_NOT_SET = 'AiNotSet',
}

export enum OBJECT_MODERATION_HUMAN_REVIEW {
  FULL_APPROVAL = 'FullApproval',
  LIMITED_APPROVAL = 'LimitedApproval',
  PRIVATE = 'Private',
  REJECTION = 'Rejection',
  NOT_SET = 'NotSet',
}

export enum OBJECT_MODERATION_REVIEW_STATUS {
  UNREVIEWED = 'Unreviewed',
  AI_REJECTED = 'AiRejected',
  AI_INCONCLUSIVE = 'AiInconclusive',
  AI_APPROVED = 'AiApproved',
  REJECTION = 'Rejection',
  LIMITED_APPROVAL = 'LimitedApproval',
  FULL_APPROVAL = 'FullApproval',
  PRIVATE = 'Private',
}

export enum OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED {
  NEW = 'New',
  UPDATED = 'Updated',
  FALSE = 'False',
  REPORTED = 'Reported',
}

export enum ObjectType {
  VIDEO = 'video',
}

export enum ObjectUserStatus {
  PUBLIC = 'Public',
  PRIVATE = 'Private',
}

export enum ObjectAdminStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum ObjectVisibility {
  EVERYWHERE = 'Everywhere',
  LIMITED = 'Limited',
  PRIVATE = 'Private',
  HIDDEN = 'Hidden',
  NOT_VISIBLE = 'NotVisible',
}

export enum ObjectVisibleStatus {
  PUBLIC = 'Public',
  PRIVATE = 'Private',
  IN_REVIEW = 'InReview',
  NEEDS_REVISION = 'NEEDS_REVISION',
}

export enum ObjectActionablesStatus {
  ON = 'On',
  OFF = 'Off',
  ONLY_PRO = 'OnlyPRO',
}

export enum ModerationCategory {
  ADULT = 'adult',
  COPYRIGHT = 'copyright',
  HATE = 'hate',
  SELF_HARM = 'selfHarm',
  SUSPICIOUS = 'suspicious',
  VIOLENCE = 'violence',
  // NEW
  TECHNICAL_ISSUE = 'technicalIssue',
  CONTENT_MISLEADING = 'contentMisleading',
  INVALID_SUBMISSION = 'invalidSubmission',
  IMPROVEMENT_NEEDED = 'improvementNeeded',
  OTHER = 'other',
}

export type ObjectEditability = ObjectActionablesStatus;
export type ObjectMonetization = ObjectActionablesStatus;
export type ObjectSocialActionable = ObjectActionablesStatus;
export type ObjectTransferActionable = ObjectActionablesStatus;

export type ObjectActionables = {
  editability: ObjectActionablesStatus;
  monetization: ObjectActionablesStatus;
  social: ObjectActionablesStatus;
  transfer: ObjectActionablesStatus;
};

export type ObjectStatuses = {
  userStatus: ObjectUserStatus;
  visibility: ObjectVisibility;
  reviewStatus: OBJECT_MODERATION_REVIEW_STATUS;
  visibleStatus: ObjectVisibleStatus;
  humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED;
  lastObjectModerationUuid?: string;
};

export type WithActionables<T> = T & ObjectActionables;
export type WithObjectStatuses<T> = T & ObjectStatuses;
export type WithObjectModerationProps<T> = WithObjectStatuses<
  WithActionables<T>
>;

export type StatusesAndActionablesOnObjectParams = Omit<
  ObjectStatuses,
  'userStatus' | 'lastReviewUuid' | 'lastObjectModerationUuid'
> &
  ObjectActionables;
