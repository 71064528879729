import PreviewQrCodeModal from 'src/components/shared/QrCodeUrlPreviewer/QrCodeModal';
import useModalStore from 'src/lib/stores/useModalStore';

interface OpenQrCodeModalProps {
  title: string;
  url: string;
  size?: number;
}

export const useQrCodeModal = () => {
  const { openModal } = useModalStore();

  const handleOpenQrCodeModal = ({
    title,
    url,
    size,
  }: OpenQrCodeModalProps) => {
    openModal(<PreviewQrCodeModal title={title} url={url} size={size} />);
  };

  return { handleOpenQrCodeModal };
};
