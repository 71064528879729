import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { Game } from 'src/lib/types/game';
import { getMessageFromError } from 'src/lib/utils/error';

export async function toggleAssetCollectionActive(
  uuid: string
): Promise<ServiceResponse<Game>> {
  try {
    const api = await getAPIClient();
    const res = await api.put(
      `admin/game-maker-asset-collection/${uuid}/toggle-active`
    );
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
