import { debounce } from 'lodash';
import { useCallback, useEffect } from 'react';
import { ReviewEmitter } from 'src/components/modules/Review/events/ReviewEmitter';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useNotify from 'src/lib/hooks/useNotify';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { AdminListGamesRow } from 'src/lib/types/gameRow';
import { OBJECT_MODERATION_REVIEW_STATUS } from 'src/lib/types/objectModeration';
import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import useTabs from 'src/template/hooks/useTabs';
import { GameEmitter } from '../../events/GameEmitter';
import { downloadGamesCSV as downloadActiveGamesCSV } from '../../services/downloadCsv';
import { fetchGames } from '../../services/fetchGames';
import { UpdateGameStatus } from '../../services/updateGameStatus';
import CreateGameView from '../Create';

export default function useGames() {
  const openModal = useModalStore((state) => state.openModal);
  const notify = useNotify();

  const { addParam, removeParam, allSearchParams } =
    useURLSearchParams(initialParams);
  function handleChangeSearch(value: string, param: string = 'query') {
    if (!value) removeParam(param);
    else addParam(param, value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 1000);

  const { currentTab, onChangeTab } = useTabs('all', {
    queryName: 'tab',
  });

  const handleOpenCreateModal = () => {
    openModal(<CreateGameView />);
  };

  const handleDownloadActiveGamesCSV = async () => {
    const error = await downloadActiveGamesCSV();
    return error;
  };

  const params = {
    page: +(allSearchParams.page ?? initialParams.page),
    size: +(allSearchParams.size ?? initialParams.size),
    orderBy: allSearchParams.orderBy ?? initialParams.orderBy,
    sortOrder: allSearchParams.order ?? initialParams.order,
    searchBy: allSearchParams.searchBy ?? initialParams.searchBy,
    query: allSearchParams.query ?? initialParams.query,
    bySGM: allSearchParams.bySGM ?? initialParams.bySGM,
    visibility: allSearchParams.visibility ?? initialParams.visibility,
    isTemplate: allSearchParams.isTemplate ?? initialParams.isTemplate,
  };

  const { data, isLoading, mutate } = fetchGames({
    page: params.page,
    size: params.size,

    ...(params.orderBy &&
      params.sortOrder && {
        order: {
          [params.orderBy]: params.sortOrder,
        },
      }),

    filter: {
      ...(params.searchBy &&
        params.query && {
          [params.searchBy]: {
            like: `${params.query}%`,
          },
        }),
      ...(!['all', 'humanAnalysisNeeded'].includes(currentTab ?? '') && {
        reviewStatus: currentTab as OBJECT_MODERATION_REVIEW_STATUS,
      }),
      ...(currentTab == 'humanAnalysisNeeded' && {
        humanAnalysisNeeded: true,
      }),
      ...(params.bySGM !== 'all' && {
        isAi: params.bySGM === IncludeExcludeAll.Include,
      }),
      ...(params.visibility !== 'all' && {
        visibility: {
          like: params.visibility,
        },
      }),
      ...(params.isTemplate !== 'all' && {
        isTemplate: params.isTemplate === IncludeExcludeAll.Include,
      }),
    },
  });

  //! TODO: move to table
  async function handleChangeGameStatus(
    uuid: string,
    currentStatus: 'active' | 'inactive'
  ) {
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';

    await UpdateGameStatus(uuid, {
      status: newStatus,
    })
      .then(() => {
        notify('Changed game status successfully', {
          variant: 'success',
        });

        const newData = data?.rows.map((game) => {
          if (game.uuid === uuid) {
            return {
              ...game,
              status: newStatus,
            };
          }
          return game;
        }) as AdminListGamesRow[];

        mutate({ rows: newData, count: data?.count ?? 0 });
      })
      .catch(() => {
        notify('Failed to change game status', {
          variant: 'error',
        });
      });
  }

  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    ReviewEmitter.on('ReviewCreated', refetchData);
    GameEmitter.on('GameCreated', refetchData);
    GameEmitter.on('GameUpdated', refetchData);
    GameEmitter.on('GameDeleted', refetchData);
    GameEmitter.on('GameStatusChanged', refetchData);
    return () => {
      ReviewEmitter.off('ReviewCreated', refetchData);
      GameEmitter.off('GameCreated', refetchData);
      GameEmitter.off('GameUpdated', refetchData);
      GameEmitter.off('GameDeleted', refetchData);
    };
  }, [refetchData]);

  return {
    data,
    isLoading,
    params,
    handleOpenCreateModal,
    handleChangeGameStatus,
    handleDownloadActiveGamesCSV,
    currentTab,
    onChangeTab,
    debouncedSearch,
    addParam,
    removeParam,
    allSearchParams,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'createdAt',
  searchBy: 'name',
  query: '',
  bySGM: 'all',
  visibility: 'all',
  isTemplate: 'all',
};

export type FetchGamesQueryParams = {
  page: number;
  size: number;
  orderBy: string;
  searchBy: string;
  sortOrder: string;
  visibility: string;
};
