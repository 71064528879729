import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { GameEmitter } from 'src/components/modules/Games/events/GameEmitter';
import { fetchGamesByAdmin } from 'src/components/modules/Games/services/fetchGamesByAdmin';
import { ReviewEmitter } from 'src/components/modules/Review/events/ReviewEmitter';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';

export default function useUserGames() {
  const { allSearchParams } = useURLSearchParams(initialParams);
  const { uuid: userUuid } = useParams<{ uuid: string }>();

  const params = {
    page: +(allSearchParams.page ?? initialParams.page),
    size: +(allSearchParams.size ?? initialParams.size),
    orderBy: allSearchParams.orderBy ?? initialParams.orderBy,
    sortOrder: allSearchParams.order ?? initialParams.order,
  };

  const { data, isLoading, mutate } = fetchGamesByAdmin(userUuid!, {
    page: params.page,
    size: params.size,

    ...(params.orderBy &&
      params.sortOrder && {
        order: {
          [params.orderBy]: params.sortOrder,
        },
      }),
  });

  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    ReviewEmitter.on('ReviewCreated', refetchData);
    GameEmitter.on('GameCreated', refetchData);
    GameEmitter.on('GameUpdated', refetchData);
    GameEmitter.on('GameDeleted', refetchData);
    GameEmitter.on('GameStatusChanged', refetchData);
    return () => {
      ReviewEmitter.off('ReviewCreated', refetchData);
      GameEmitter.off('GameCreated', refetchData);
      GameEmitter.off('GameUpdated', refetchData);
      GameEmitter.off('GameDeleted', refetchData);
    };
  }, [refetchData]);

  return {
    userUuid,
    data,
    isLoading,
    params,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'createdAt',
};

export type FetchUserGamesQueryParams = {
  page: number;
  size: number;
  orderBy: string;
  sortOrder: string;
};
