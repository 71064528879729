import { DeepPartial } from 'react-hook-form';
import { Operators } from 'src/lib/types/Operators';
import {
  DefaultMetaType,
  PaginatedResponse,
} from 'src/lib/types/PaginatedResponse';
import { getMessageFromError } from 'src/lib/utils/error';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

type FilterKeys =
  | 'userUuid'
  | 'country'
  | 'productId'
  | 'expiresOn'
  | 'productUuid'
  | 'isFreeTrial';

type PossibleParams = {
  filter: Record<FilterKeys, string | number | Operators>;
  size: number | string;
  page: number | string;
};

type FetchSubscriptionsFromRevenueEventsV2Meta = DefaultMetaType & {
  withUsers: number;
  withoutUsers: number;
};

export function fetchSubscriptionsFromRevenueEventsV2(
  params?: DeepPartial<PossibleParams>
): SWRResponse<
  PaginatedResponse<
    GetSubscriptionsSalesReturnValue[],
    FetchSubscriptionsFromRevenueEventsV2Meta
  >
> {
  const parsedParams = parseSearchParams(params ?? {});

  const { error, ...response } = useSWR(
    `admin/revenue-events/subscriptions/sales?${parsedParams}`
  );

  return {
    ...response,
    error: error ? getMessageFromError(error) : undefined,
  };
}

export type GetSubscriptionsSalesReturnValue = {
  uuid: string;
  product: {
    uuid: string;
    productId: string; // Apple productId
    name: string;
  };
  user: {
    uuid: string;
    slug: string;
    name: string;
    region: string;
    systemRegion: string;
    email: string;
    active: boolean;
    profileImage: string;
  };
  eventType: string;
  originalTransactionId: string;
  paymentCount: number;
  eventDate: Date;
  purchaseOrRenewalDate: Date;
  originalPurchaseDate: Date;
  subscriptionExpiresDate: Date;
  subscriptionUuid: string;
};
