import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { Game } from 'src/lib/types/game';
import { getMessageFromError } from 'src/lib/utils/error';

export type CreateAssetCollectionInput = {
  name: string;
  description?: string;
  creatorUuid: string;
};

export async function CreateAssetCollection(
  data: CreateAssetCollectionInput
): Promise<ServiceResponse<Game>> {
  try {
    const api = await getAPIClient();
    const res = await api.post(`admin/game-maker-asset-collection`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
