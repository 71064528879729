import { Controller, UseFormReturn } from 'react-hook-form';

import { FormHelperText } from '@mui/material';
import { useState } from 'react';
import Autocomplete, {
  AutoCompleteOption,
} from 'src/components/modules/ChannelContent/components/Forms/ChannelContent/components/Autocomplete';
import { fetchUsersByQuery } from 'src/components/modules/Users/services/fetchUsersByQuery';
import useDebounce from 'src/lib/hooks/useDebounce';
import { User } from 'src/lib/types/users';

export const useUserSearch = (params: {
  form: UseFormReturn<any>;
  defaultUser?: User;
  userUuidField: string;
}) => {
  const [searchText, setSearchText] = useState<string>('');

  const handleInputChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setSearchText(newValue);
  };

  const { data: searchUsers, isLoading: isLoadingSearchUsers } =
    fetchUsersByQuery({
      order: {
        slug: 'asc',
      },
      page: 0,
      size: 100,
      filter: {
        slug: {
          like: `${useDebounce(searchText, 500)}%`,
        },
      },
    });

  const autoCompleteOptions: AutoCompleteOption[] = (searchUsers ?? []).map(
    (user) => ({
      uuid: user.uuid,
      label: user.slug!,
      img: user.profileImage!,
    })
  );

  return {
    userSearchProps: {
      form: params.form,
      autoCompleteOptions,
      isLoadingSearchUsers,
      handleInputChange,
      defaultUser: params.defaultUser,
      userUuidField: params.userUuidField,
    },
    UserSearchComponent,
  };
};

interface UserSearchComponentProps {
  form: UseFormReturn<any>;
  autoCompleteOptions: AutoCompleteOption[];
  isLoadingSearchUsers: boolean;
  handleInputChange: (event: React.ChangeEvent<{}>, newValue: any) => void;
  defaultUser?: User;
  userUuidField: string;
}

export function UserSearchComponent(props: UserSearchComponentProps) {
  const {
    form,
    autoCompleteOptions,
    isLoadingSearchUsers,
    handleInputChange,
    defaultUser,
    userUuidField,
  } = props;
  const fieldError = form.getFieldState(userUuidField)?.error?.message;
  return (
    <Controller
      name={userUuidField}
      control={form.control}
      render={({ field }) => (
        <>
          <Autocomplete
            label={'Search a user by slug'}
            options={autoCompleteOptions}
            isLoading={isLoadingSearchUsers}
            handleInputChange={handleInputChange}
            handleChange={(event, value) =>
              form.setValue(userUuidField, value?.uuid || '')
            }
            defaultValue={{
              label: defaultUser?.slug ?? '',
              img: defaultUser?.profileImage ?? '',
              uuid: defaultUser?.uuid ?? '',
            }}
            {...field}
          />
          <FormHelperText error>{fieldError}</FormHelperText>
        </>
      )}
    />
  );
}
