import {
  Card,
  CardContent,
  CardHeader,
  InputLabel,
  Stack,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { Controller } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import SelectOption from 'src/components/shared/SelectOption';
import UploadContent, {
  UploadTypes,
} from 'src/components/shared/UploadContent';
import { GameMakerAssetTypes } from 'src/lib/types/gameMakerAsset';
import objectEntries from 'src/lib/utils/objectEntries';
import { AssetFormValues } from './types';
import useGameFormView from './useView';

type Props = {
  defaultValues: AssetFormValues;
  onSubmit: (values: AssetFormValues) => Promise<void>;
  title: string;
};

export default function AssetForm({
  defaultValues,
  title,
  onSubmit,
}: Props): JSX.Element {
  const {
    form: { control, handleSubmit },
    isSubmitting,
    handleCloseModal,
    onUpdateContent,
    isUpdatingContent,
    assetType,
    setValue,
  } = useGameFormView({ defaultValues });

  return (
    <Modal open onClose={handleCloseModal}>
      <Card
        sx={{
          width: '600px',
        }}
      >
        <CardHeader title={title} />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack mb={2} spacing={1.5}>
              <Stack mb={2} spacing={1.5}>
                <ControllerField
                  control={control}
                  name="name"
                  label="Name"
                  fullWidth
                />
                <ControllerField
                  control={control}
                  name="description"
                  label="Description"
                  fullWidth
                  multiline
                  rows={4}
                />
                <ControllerField
                  control={control}
                  name="aiDescription"
                  label="AI Description"
                  fullWidth
                  multiline
                  rows={4}
                />
                <ControllerField
                  control={control}
                  name="assetType"
                  fullWidth
                  label="Asset Type"
                  select
                  wrapperProps={{
                    sx: {
                      gridColumn: '1 / span 2',
                    },
                  }}
                >
                  {objectEntries(GameMakerAssetTypes).map(([label, value]) => (
                    <SelectOption value={value} key={value}>
                      {capitalCase(label)}
                    </SelectOption>
                  ))}
                </ControllerField>

                <Controller
                  name="url"
                  control={control}
                  render={({ field }) => (
                    <Stack spacing={2}>
                      <InputLabel htmlFor="my-input">Url</InputLabel>
                      <UploadContent
                        {...field}
                        type={
                          assetType == GameMakerAssetTypes.IMAGE
                            ? UploadTypes.IMAGE
                            : UploadTypes.AUDIO
                        }
                        uploadButtonLabel={
                          assetType == GameMakerAssetTypes.IMAGE
                            ? 'Upload Image'
                            : 'Upload Audio'
                        }
                        changeButtonLabel={
                          assetType == GameMakerAssetTypes.IMAGE
                            ? 'Change Image'
                            : 'Change Audio'
                        }
                        content={field.value ?? ''}
                        onEdit={(file) => {
                          onUpdateContent(file);
                        }}
                        onRemove={() => {
                          setValue('url', '');
                        }}
                        showEditButton
                        sx={{
                          maxWidth: '300px',
                          maxHeight: '300px',
                          minHeight: '30px',
                          minWidth: '100px',
                        }}
                        isLoading={isUpdatingContent}
                      />
                    </Stack>
                  )}
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1}
                mt={2}
              >
                <Button color="error" onClick={handleCloseModal} size="large">
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={isSubmitting}
                  size="large"
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
