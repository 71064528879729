import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useModalStore from 'src/lib/stores/useModalStore';
import { GameMakerAsset } from 'src/lib/types/gameMakerAsset';
import { uploadToStorage } from 'src/lib/utils/upload';
import * as yup from 'yup';
import { AssetFormValues } from './types';

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  url: yup.string().required('Url is required'),
});

function useAssetFormView({
  defaultValues,
}: {
  defaultValues: AssetFormValues;
  asset?: GameMakerAsset;
}) {
  const onClose = useModalStore((state) => state.closeModal);
  const form = useForm<AssetFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    formState: { isSubmitting },
    setValue,
    watch,
  } = form;

  const handleCloseModal = () => {
    onClose();
  };

  // Upload content for url
  const [isUpdatingContent, setIsUpdatingContent] = useState<boolean>(false);

  const onUpdateContent = async (file: any) => {
    setIsUpdatingContent(true);

    try {
      const assetUuid = defaultValues.uuid;
      const url = await uploadToStorage(`sgm-asset/${assetUuid}`, file, 'code');

      setValue('url', url as string);
    } finally {
      setIsUpdatingContent(false);
    }
  };

  const assetType = watch('assetType');

  // Control for initial rendering to prevent resetting the URL
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (isInitialized) {
      setValue('url', '');
    } else {
      setIsInitialized(true);
    }
  }, [assetType, setValue]);

  return {
    form,
    isSubmitting,
    handleCloseModal,
    setValue,
    assetType,
    onUpdateContent,
    isUpdatingContent,
  };
}

export default useAssetFormView;
